import { Button, Col, Form, Row, Table } from "react-bootstrap";
import DashboardContent from "./DashboardContent";
import { useEffect, useState } from "react";
import { del, get, put } from "./rest";
import EditResultModal from "./EditResultModal";
import ViewResultModal from "./ViewResultModal";
import Evaluate from "./Evaluate";

function Results() {
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [currentOrgAdmin, setCurrentOrgAdmin] = useState(null);
  const organizationid = sessionStorage.getItem("organizationid");
  const roleslug = sessionStorage.getItem("roleslug");
  const roleid = sessionStorage.getItem("roleid");
  const authorid = sessionStorage.getItem("authorid");
  useEffect(() => {
    fetchResults();
  }, []);

  const fetchResults = async () => {
    const organizationid = sessionStorage.getItem("organizationid");
    const roleslug = sessionStorage.getItem("roleslug");
    const roleid = sessionStorage.getItem("roleid");
    const authorid = sessionStorage.getItem("authorid");
    try {
      const response = await get('assessmentreports');
      const orgAdmins = roleslug === 'student'
        ? response.response.filter(result => result.user.role === roleid && result.organization.id === organizationid && result.user.id === authorid)
        : response.response.filter(result => result.organization.id === organizationid);
        
      setResults(orgAdmins);
      debugger;
      setFilteredResults(orgAdmins);
    } catch (error) {
      console.error('Error fetching Results:', error);
    }
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    filterResults(e.target.value);
  };
  const prepareResults = (results) => {
    return results.map(result => {
      const correctPercentageText = result.correctPercentage !== null
        ? result.correctPercentage + "%"
        : (roleslug === 'student'
          ? 'Evaluating'
          : result.submissionStatus === "saved"
            ? 'Not Submitted'
            : 'Evaluate');
            
      const passFailText = result.correctPercentage === null
        ? (roleslug === 'student' && result.submissionStatus === "saved"
          ? 'Not Submitted'
          : 'Evaluating')
        : result.assessment.averagePassPercentage <= result.correctPercentage
          ? "Pass"
          : "Fail";
  
      return {
        ...result,
        correctPercentageText,
        passFailText
      };
    });
  };
  
  useEffect(() => {
    const fetchAndPrepareResults = async () => {
      try {
        const response = await get('assessmentreports');
        const orgAdmins = roleslug === 'student'
          ? response.response.filter(result => result.user.role === roleid && result.organization.id === organizationid && result.user.id === authorid)
          : response.response.filter(result => result.organization.id === organizationid);
  
        const preparedResults = prepareResults(orgAdmins);
        setResults(preparedResults);
        setFilteredResults(preparedResults);
      } catch (error) {
        console.error('Error fetching Results:', error);
      }
    };
  
    fetchAndPrepareResults();
  }, []);
  
  const filterResults = (query) => {
    const queryLowerCase = query.toLowerCase();
  
    console.log('Filtering results with query:', queryLowerCase);
  
    const filteredOrgAdmins = results.filter(OrgAdmin => {
      const username = OrgAdmin.user.username.toLowerCase();
      const assessmentTitle = OrgAdmin.assessment?.title.toLowerCase() || '';
      const correctPercentageText = OrgAdmin.correctPercentageText.toLowerCase();
      const passFailText = OrgAdmin.passFailText.toLowerCase();
      const difficultyLevel = OrgAdmin.assessment?.difficultyLevel.toLowerCase() || '';
  
      console.log({
        username,
        assessmentTitle,
        correctPercentageText,
        passFailText,
        difficultyLevel
      });
  
      return (
        username.includes(queryLowerCase) ||
        assessmentTitle.includes(queryLowerCase) ||
        correctPercentageText.includes(queryLowerCase) ||
        passFailText.includes(queryLowerCase) ||
        difficultyLevel.includes(queryLowerCase)
      );
    });
  
    console.log('Filtered results:', filteredOrgAdmins);
  
    setFilteredResults(filteredOrgAdmins);
    setCurrentPage(1);
  };
  
    

  const indexOfLastOrgAdmin = currentPage * resultsPerPage;
  const indexOfFirstOrgAdmin = indexOfLastOrgAdmin - resultsPerPage;
  const currentOrgAdmins = filteredResults.slice(indexOfFirstOrgAdmin, indexOfLastOrgAdmin);

  async function handleDelete(Id) {
    try {
      await del(`assessmentreports/${Id}`);
      fetchResults();
    } catch (error) {
      console.error('Error deleting industries:', error);
    }
  }

  const handleEdit = (OrgAdmin) => {
    setCurrentOrgAdmin(OrgAdmin);
    window.location.href = './EditResult?id=' + OrgAdmin.id;
    
  };
  
  const handleEvaluate = (OrgAdmin) => {
    setCurrentOrgAdmin(OrgAdmin);
    setShowEditModal(true);
  };

  const handleView = (OrgAdmin) => {
    setCurrentOrgAdmin(OrgAdmin);
    setShowViewModal(true);
  };

  const retake = (OrgAdmin) => {
    debugger;
    if(OrgAdmin.slotId === null){
      debugger;
      window.location.href = './EditResult?id=' + OrgAdmin.assessment.id + "&retake=retake";
    } else {
      window.location.href = "/assessment?id=" + OrgAdmin.assessment.id + "&quiz=" + OrgAdmin.slotId + "&retake=retake";
    }
    
    // setCurrentOrgAdmin(OrgAdmin);
    // setShowViewModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleCloseViewModal = () => {
    setShowViewModal(false);
  };

  const updateOrgAdmin = async (id, updatedData) => {
    try {
      const updatedFormData = {
        ...updatedData,
        updatedBy : sessionStorage.getItem("authorid")
      };
      await put(`assessmentreports/${id}`, updatedFormData);
      fetchResults();
    } catch (error) {
      console.error('Error updating OrgAdmin:', error);
    }
  };

  const getPageNumbers = () => {
    const totalPages = Math.ceil(filteredResults.length / resultsPerPage);
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pageNumbers.push(1, '...');
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      if (endPage < totalPages) pageNumbers.push('...', totalPages);
    }
    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(filteredResults.length / resultsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <DashboardContent pageTitle="Assessments">
      <Row>
        <Col md={4} className="form-group my-5">
          <Form.Control type="text" name="name" value={searchQuery} placeholder="Search ..." className="form-control" onChange={handleChange} />
        </Col>
      </Row>
      <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
        <Table className="table display data-table" striped hover responsive>
          <thead>
            <tr>
              <th>Assessment</th>
              <th>Student </th>              
              <th>Percentage</th>
              <th>Pass/Fail</th>
              <th>Difficulty Level</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody id="requestedusers">
            {currentOrgAdmins.map((OrgAdmin, index) => (
              <tr key={index}>
                <td>{OrgAdmin.assessment ? OrgAdmin.assessment.title : ""}</td>
                <td>{OrgAdmin.user.username}</td>
                <td>
                  {OrgAdmin.correctPercentage !== null 
                    ? OrgAdmin.correctPercentage + "%" 
                    : (roleslug === 'student' 
                        ? <span style={{color:'red'}}>Evaluating</span>
                        : OrgAdmin.submissionStatus === "saved" 
                          ? 'Not Submitted' 
                          : <>
                              <span>Evaluate </span>
                              <i onClick={() => handleEvaluate(OrgAdmin)} className='fa fa-edit' style={{ paddingRight: '10px' }}></i>
                            </>
                      )}
                </td>
                <td>
                  {OrgAdmin.correctPercentage === null 
                    ? (roleslug === 'student' && OrgAdmin.submissionStatus === "saved" ? 'Not Submitted' : <span style={{color:'red'}}>Evaluating</span>)
                    : OrgAdmin.assessment.averagePassPercentage <= OrgAdmin.correctPercentage 
                      ? "Pass" 
                      : "Fail"
                  }
                </td>
                <td>{OrgAdmin.assessment.difficultyLevel}</td>
                <td>
                  {roleslug === 'student' && OrgAdmin.submissionStatus === "saved" && <i onClick={() => handleEdit(OrgAdmin)} className='fa fa-edit' style={{ paddingRight: '10px' }}></i>}
                  {OrgAdmin.submissionStatus === "submitted" && <i onClick={() => handleView(OrgAdmin)} className='fa fa-eye' style={{ paddingRight: '10px' }}></i>}
                  {OrgAdmin.submissionStatus === "submitted" && <i onClick={() => retake(OrgAdmin)} className='fa fa-rotate-right' style={{ paddingRight: '10px' }}></i>}
                  <i onClick={() => handleDelete(OrgAdmin.id)} className="fa-regular fa-trash-can"></i>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <nav className='d-flex justify-content-end my-5 align-items-center'>
        <Button
          className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={handlePrevious}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <ul className="pagination">
          {getPageNumbers().map((pageNumber, index) => (
            <li
              key={index}
              className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
            >
              {pageNumber === '...' ? (
                <span className="page-link">...</span>
              ) : (
                <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
              )}
            </li>
          ))}
        </ul>
        <Button
          className={`btn plain-btn ${currentPage === Math.ceil(filteredResults.length / resultsPerPage) ? 'disabled' : ''}`}
          onClick={handleNext}
          disabled={currentPage === Math.ceil(filteredResults.length / resultsPerPage)}
        >
          Next
        </Button>
      </nav>
      <Evaluate
        show={showEditModal}
        handleClose={handleCloseEditModal}
        OrgAdmin={currentOrgAdmin}
        updateOrgAdmin={updateOrgAdmin}
      />
      <ViewResultModal
        show={showViewModal}
        handleClose={handleCloseViewModal}
        OrgAdmin={currentOrgAdmin}
      />
    </DashboardContent>
  );
}

export default Results;
