import React, { useEffect, useState } from 'react';
import DashboardContent from './DashboardContent';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { get, post } from './rest';
import SelectField from './SelectField';
import CustomSelectField from './CustomSelectField';
import QuillEditor from "./QuillEditor";
import UnitSelectField from './UnitSelectField';

function CreateLMS() {
  const [formData, setFormData] = useState({
    // thumbnail_URL: '',
    // // pdf_URL: '',
    // // video_URL: '',
    // lms_cluster: '',
    // tabData: '',
    // metatitle:'',
    // metadescription:'',
    // lesson_title:'',
    // lesson_description:'',
    // course:'',
    // category:'',
    // courseModule:'',
    // courseChapter:''
  });
  const [groups, setGroups] = useState([{}]);
  const [optionsIndustries, setOptionsIndustries] = useState([]);
  const [optionsCourses, setOptionsCourse] = useState([]);
  const [optionsModuleCourses, setOptionsModuleCourse] = useState([]);
  const [optionsUnitCourses, setOptionsUnitCourse] = useState([]);
  const [selectedOptionCourses, setSelectedOptionCourses] = useState(null);
  const [selectedOptionIndustries, setSelectedOptionIndustries] = useState(null);
  const [selectedOptionModule, setSelectedOptionModule] = useState();

  const [selectedOptionUnit, setSelectedOptionUnit] = useState();
  const [thumbnailURL, setThumbnailURL] = useState('');
  const [videoURL, setVideoURL] = useState('');
  const [docURL, setDocURL] = useState('');
  const [creating, setCreating] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newModuleFormData, setNewModuleFormData] = useState({
    title: '',
  });
  const [newUnitFormData, setNewUnitFormData] = useState({
    title: '',
  });

  useEffect(() => {
    fetchIndustriesOptions();
    fetchCourseOptions();
    fetchCourseModuleOptions();
  }, []);

  const fetchIndustriesOptions = async () => {
    try {
      const response = await get('categories');
      const options = response.response.map(role => ({
        value: role.id,
        label: role.industry
      }));
      setOptionsIndustries(options);
    } catch (error) {
      console.error('Error fetching Industries options:', error);
    }
  };

  const fetchCourseOptions = async () => {
    try {
      let organizationid = sessionStorage.getItem("organizationid");
      const response = await get('courses');
      const options = response.response.filter(c => c.organization && c.organization === organizationid).map(course => ({
        value: course.id,
        label: course.course_title
      }));
      setOptionsCourse(options);
    } catch (error) {
      console.error('Error fetching Courses options:', error);
    }
  };

  const fetchCourseModuleOptions = async (selectedOptionCourses) => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`coursemodule?course=${selectedOptionCourses}`);
      const options = response.response
        .map((module) => {
          if (module.organization === organizationid) {
            return {
              value: module.id,
              label: module.title,
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsModuleCourse(options);
    }
     catch (error) {
      console.error('Error fetching Courses options:', error);
    }
  };
  const fetchCourseModuleOption = async (filter) => {
    try {
      var organizationid = sessionStorage.getItem("organizationid");

      const response = await get('coursemodule');
      const options = response.response.map(module => {
        if (module.organization === organizationid) {
          return {
            value: module.id,
            label: module.title
          };
        }
        // If the condition is not met, return null or undefined
        // (this is optional and depends on how you want to handle non-matching items)
        return null; // or undefined
      }).filter(Boolean); // This filters out null or undefined elements
      
      setOptionsModuleCourse(options);
  
      // Find the module object with the matching label or value
      const selectedModule = options.find(module => module.label === filter || module.value === filter);
  
      if (selectedModule) {
        setSelectedOptionModule(selectedModule); 
        setFormData(prevState => ({
          ...prevState,
          courseModule: selectedModule.value
        }));
      }
    } catch (error) {
      console.error('Error fetching Courses options:', error);
    }
  };
  const fetchCourseUnitOption = async (selectedOptionModule) => {
    debugger;
    setOptionsUnitCourse([]);
    console.log(optionsUnitCourses);
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`coursechapter?courseModule=${selectedOptionModule}`);
      debugger;
      const options = response.response
        .map((unit) => {
          if (unit.organization === organizationid) {
            // if (unit.organization === organizationid) {
            return {
              value: unit.id,
              label: unit.title,
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsUnitCourse(options);
    } catch (error) {
      console.error("Error fetching Unit options:", error);
    }
  };
  const fetchCourseModuleOptionfilter = async (courseId, filter) => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`coursemodule?course=${courseId}`);
      const options = response.response
        .map((module) => {
          if (module.organization === organizationid) {
            return {
              value: module.id,
              label: module.title,
              isselected: module.title === filter
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsModuleCourse(options);
      const selectedModule = options.find(module => module.label === filter || module.value === filter);
  
      if (selectedModule) {
        debugger;
        setSelectedOptionModule(selectedModule.value); 
        setFormData(prevState => ({
          ...prevState,
          courseModule: selectedModule.value
        }));
      }
    } catch (error) {
      console.error('Error fetching Module options:', error);
    }
  };
  
  const fetchCourseUnitOptionfilter = async (moduleId, filter) => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`coursechapter?courseModule=${moduleId}`);
      const options = response.response
        .map((unit) => {
          if (unit.organization === organizationid) {
            return {
              value: unit.id,
              label: unit.title,
              isselected: unit.title === filter
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsUnitCourse(options);
      
      const selectedUnit = options.find(Unit => Unit.label === filter || Unit.value === filter);
  
      if (selectedUnit) {
        setSelectedOptionUnit(selectedUnit.value); 
        setFormData(prevState => ({
          ...prevState,
          courseChapter: selectedUnit.value
        }));
      }
    } catch (error) {
      console.error('Error fetching Unit options:', error);
    }
  };

  const handleThumbnailChange = async (event, index) => {
    setUploading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file);
    formData.append('path', 'default/lms/thumbnail');
  
    try {
      const response = await fetch('http://greenbugapi.adef.tech/upload', {
        method: 'POST',
        body: formData,
      });
      if (response.status === 201) {
        const data = await response.json();
        if (data.response.length > 0) {
          const firstObject = data.response[0];
          const url = firstObject.url;
  
          if (index !== undefined && index !== null && !isNaN(index)) {
            setGroups(prevGroups => {
              const updatedGroups = [...prevGroups];
              updatedGroups[index] = {
                ...updatedGroups[index],
                thumbnail_URL: url
              };
              return updatedGroups;
            });
          } else {
            setThumbnailURL(url);
          }
  
          toast.success('Thumbnail Uploaded successfully');
        }
      } else {
        toast.error('Failed to upload thumbnail');
      }
    } catch (error) {
      console.error('Error uploading thumbnail:', error);
      toast.error('Failed to upload thumbnail');
    } finally {
      setUploading(false);
    }
  };
  
  const handleDocChange = async (event, index) => {
    setUploading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file);
    formData.append('path', 'default/lms/Documents');
  
    try {
      const response = await fetch('http://greenbugapi.adef.tech/upload', {
        method: 'POST',
        body: formData,
      });
      if (response.status === 201) {
        const data = await response.json();
        if (data.response.length > 0) {
          const firstObject = data.response[0];
          const url = firstObject.url;
  
          // If an index is provided, update the document URL in the corresponding group
          if (index !== undefined && index !== null && !isNaN(index)) {
            setGroups(prevGroups => {
              const updatedGroups = [...prevGroups];
              updatedGroups[index] = {
                ...updatedGroups[index],
                pdf_URL: url
              };
              return updatedGroups;
            });
          } else {
            // If no index is provided, update the document URL in the main formData
            setDocURL(url);
          }
  
          toast.success('Document Uploaded successfully');
        }
      } else {
        toast.error('Failed to upload');
      }
    } catch (error) {
      console.error('Error uploading document:', error);
      toast.error('Failed to upload document');
    } finally {
      setUploading(false); // Set uploading to false when upload completes
    }
  };
  const handleVideoChange = async (event, index) => {
    setUploading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file);
    formData.append('path', 'default/lms/videos');
  
    try {
      const response = await fetch('http://greenbugapi.adef.tech/upload', {
        method: 'POST',
        body: formData,
      });
      if (response.status === 201) {
        const data = await response.json();
        if (data.response.length > 0) {
          const firstObject = data.response[0];
          const url = firstObject.url;
  
          if (index !== undefined && index !== null && !isNaN(index)) {
            setGroups(prevGroups => {
              const updatedGroups = [...prevGroups];
              updatedGroups[index] = {
                ...updatedGroups[index],
                video_URL: url
              };
              return updatedGroups;
            });
          } else {
            setVideoURL(url);
          }
  
          toast.success('Video Uploaded successfully');
        }
      } else {
        toast.error('Failed to upload video');
      }
    } catch (error) {
      console.error('Error uploading video:', error);
      toast.error('Failed to upload video');
    } finally {
      setUploading(false);
    }
  };
  const handleChangeIndustries = (selectedOptionIndustries) => {
    setSelectedOptionIndustries(selectedOptionIndustries);
    setFormData(prevState => ({
      ...prevState,
      category: selectedOptionIndustries
    }));
  };
  const handleChangeCourses = (selectedOptionCourses) => {
    setSelectedOptionCourses(selectedOptionCourses);
    setFormData(prevState => ({
      ...prevState,
      course: selectedOptionCourses
    }));
    fetchCourseModuleOptions(selectedOptionCourses); 
    debugger;
    setOptionsUnitCourse([]);
  };
  const handleChangeModule = (selectedModule) => {
    // const selectedModule = event.target.value;
    setSelectedOptionModule(selectedModule); 
    setFormData(prevState => ({
      ...prevState,
      courseModule: selectedModule // Update formData with the selected module value
    }));
    fetchCourseUnitOption(selectedModule)
  };
  const handleChangeUnit = (selectedUnit) => {
    setSelectedOptionUnit(selectedUnit); 
    setFormData(prevState => ({
      ...prevState,
      courseChapter: selectedUnit // Update formData with the selected Unit value
    }));
  };
  const handleModuleSubmit = async (e, filter) => {
    e.preventDefault();
    const organizationid = sessionStorage.getItem("organizationid");
    // const courseId = selectedOptionCourses?.value;
    if (!selectedOptionCourses) {
      toast.error('Please select a course before creating a module');
      return;
    }
    debugger;
    try {
      debugger;
      const response = await post('coursemodule', { title: filter, organization: organizationid, course: selectedOptionCourses });
      if (response.statusCode === 201) {
        toast.success('New module added successfully', {
          onClose: () => {
            fetchCourseModuleOptionfilter(selectedOptionCourses, filter);

            setNewModuleFormData({
              title: '',
            });
            handleChangeModule(selectedOptionModule);
            // fetchCourseUnitOption(selectedOptionModule);
          // setSelectedOptionUnit(null);
          // handleChangeUnit(null);
          }
        });
      } else {
        toast.error('Failed to add new module');
      }
    } catch (error) {
      console.error('Error adding new module:', error);
      toast.error('Failed to add new module');
    }
  };
  const handleUnitSubmit = async (e, filter) => {
    e.preventDefault();
    const organizationid = sessionStorage.getItem("organizationid");
    // const moduleId = selectedOptionModule?.value;
    if (!selectedOptionModule) {
      toast.error('Please select a Module before creating a Unit');
      return;
    }
    debugger;
    try {
      debugger;
      const response = await post('/coursechapter', { title: filter, organization: organizationid,courseModule: selectedOptionModule  });
      if (response.statusCode === 201) {
        toast.success('New Unit added successfully',{
          onClose: () => {
            setNewUnitFormData({
              title: '',
            });
            fetchCourseUnitOptionfilter(selectedOptionModule, filter);
          }
        });
 
        
      } else {
        toast.error('Failed to add new Unit');
      }
    } catch (error) {
      console.error('Error adding new Unit:', error);
      toast.error('Failed to add new Unit');
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleEditorChange = (name, content) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: content,
    }));
  };
  const handleEditorChanged = (index, name, content) => {
    debugger;
    console.log("Index:", index);
    console.log("Field Name:", name);
    console.log("Content:", content);
  
    
      // Update group description in groups array
      setGroups(prevGroups => {
        const updatedGroups = [...prevGroups];
        updatedGroups[index] = {
          ...updatedGroups[index],
          [name]: content
        };
        return updatedGroups;
      });

  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreating(true);
    // const roleid = sessionStorage.getItem("roleid");
    const roleslug = sessionStorage.getItem("roleslug");
    const organizationid = sessionStorage.getItem("organizationid");
    const tabData = groups.map(group => ({
      description: group.description,
      thumbnail_URL: group.thumbnail_URL,
      video_URL: group.video_URL,
      pdf_URL: group.pdf_URL,
    liveClassLink: group.liveClassLink,
    }));
  
    var LMSCluster = '';
    if (roleslug === 'superadmin'){
      LMSCluster = '65eef8496cbe0baacc3aab6f';
    } else {
      LMSCluster = '65f29f196cbe0baacc4a3b19';
      formData.organization = organizationid;
    }
    const formDataToSend = {
      ...formData,
      thumbnail_URL: thumbnailURL,
      pdf_URL: docURL,
      video_URL: videoURL,
      lms_cluster: LMSCluster,
      tabData: tabData,
      createdBy: sessionStorage.getItem("authorid"), 
      // role = roleid;
    };
  
    try {
      debugger;
      const response = await post("lms", formDataToSend);
      
      if (response.statusCode === 201) {
        
        setFormData({});
        setThumbnailURL("");
        setDocURL("");
        setVideoURL("");
        setSelectedOptionCourses(null); 
        toast.success("LMS created Successfully", {
          onClose: () => {
            window.location.href = "/listoflms";
            // setFormData(prevData => ({
            //   thumbnail_URL: '',
            //   // pdf_URL: '',
            //   // video_URL: '',
            //   lms_cluster: prevData.lms_cluster,
            //   tabData: '',
            //   metatitle:'',
            //   metadescription:'',
            //   lesson_title:'',
            //   lesson_description:'',
            //   course: prevData.course,
            //   category: prevData.category,
            //   courseModule: prevData.coursemodule,
            //   courseChapter:prevData.coursechapter
            // }));
          },
        });
      } else {
        toast.error("Failed to create LMS");
      }
    } catch (error) {
      console.error("Error creating LMS:", error);
      toast.error("Failed to create LMS");
    } finally{
      setCreating(false);
    }
  };
  const handleAddGroup = () => {
    setGroups([...groups, { description: '' }]); // Add a new group with an empty description
  };

  const handleGroupChange = (index, field, value) => {
    const updatedGroups = [...groups];
    updatedGroups[index] = {
      ...updatedGroups[index],
      [field]: value
    };
    setGroups(updatedGroups);
  };
  const handleFieldChange = (index, field, value) => {
    const updatedGroups = [...groups];
    updatedGroups[index] = {
      ...updatedGroups[index],
      [field]: value,
    };
    setGroups(updatedGroups);
  };
  
  return (
    <DashboardContent pageTitle="LMS">
      <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3>Create LMS</h3>
                </div>
              </div>
              <div className="student-info">
                <Form onSubmit={handleSubmit} id="createLMS" className="mt-5">
                  <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Categories:</Form.Label>
                      <SelectField options={optionsIndustries} placeholder="Select Category" onChange={handleChangeIndustries} required/>
                    </Col>
                    
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Course:</Form.Label>
                      <SelectField options={optionsCourses} placeholder="Select Course" onChange={handleChangeCourses} selected={selectedOptionCourses} required/>
                    </Col>
                    </Row>
                    <Row>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      {/* <Form.Label>Module:</Form.Label> */}
                      <CustomSelectField options={optionsModuleCourses} placeholder="Select Module" onChange={handleChangeModule} selected={selectedOptionModule} isSearchable handleModuleSubmit={handleModuleSubmit} required/>
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      {/* <Form.Label>Unit:</Form.Label> */}
                      <UnitSelectField  options={optionsUnitCourses} placeholder="Select Unit" onChange={handleChangeUnit} selected={selectedOptionUnit} isSearchable handleUnitSubmit={handleUnitSubmit} required/>
                    </Col>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>Lesson Title:</Form.Label>
                    <Form.Control type="text" id="lesson_title" name="lesson_title" className="form-control" onChange={handleChange} required />
                  </Col>
                  </Row>
                    <Row>
                  <Col md={8} xl={8} lg={8} xs={12} className="form-group">
                    <Form.Label>Lesson Description:</Form.Label>
                    <QuillEditor
                          name="lesson_description"
                          value={formData.lesson_description}
                          onChange={(content) =>
                            handleEditorChange("lesson_description", content)
                          }
                        />
                    {/* <textarea type="text" id="lesson_description" name="lesson_description" className="form-control" onChange={handleChange} ></textarea> */}
                  </Col>
                  </Row>
                    <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>Meta Title:</Form.Label>
                    <Form.Control type="text" id="metatitle" name="metatitle" className="form-control" onChange={handleChange} required />
                  </Col>
                  </Row>
                    <Row>
                  <Col md={8} xl={8} lg={8} xs={12} className="form-group">
                    <Form.Label>Meta Description:</Form.Label>
                    <QuillEditor
                          name="metadescription"
                          value={formData.metadescription}
                          onChange={(content) =>
                            handleEditorChange("metadescription", content)
                          }
                        />
                    {/* <textarea type="text" id="metadescription" name="metadescription" className="form-control" onChange={handleChange} required ></textarea> */}
                  </Col>
                  </Row>
                  <Row>
                    
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label >Thumbnail:</Form.Label>
                      {/* <div className="wrapper">
                        <div className="box">
                          <div className="js--image-preview"></div>
                          <div className="upload-options"> */}
                              <Form.Control type="file" id="imgfile" className="image-upload" accept="image/*" onChange={handleThumbnailChange} required />
                          {/* </div>
                        </div>
                      </div> */}
                    </Col>
                    {/* <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label htmlFor="docfile">PDF Document:</Form.Label>
                      <div className="wrapper">
                        <div className="box">
                          <div className="js--image-preview"></div>
                          <div className="upload-options">
                            <Form.Label>
                              <Form.Control type="file" id="docfile" className="doc-upload" accept="doc/*" onChange={handleDocChange} />
                            </Form.Label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label htmlFor="videofile">Video:</Form.Label>
                      <div className="wrapper">
                        <div className="box">
                          <div className="js--image-preview"></div>
                          <div className="upload-options">
                            <Form.Label>
                              <Form.Control type="file" id="videofile" className="video-upload" accept="video/*" onChange={handleVideoChange} />
                            </Form.Label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                        <Form.Label htmlFor="liveclass">Live Class Link:</Form.Label>
                        <Form.Control type="url" id="liveclass" name="liveclass" className="form-control" onChange={handleChange} />
                    </Col> */}
                    </Row>
                    <Row className='mt-4'>
                    {groups.map((group, index) => (
  <div key={index} className="group my-4">
    
    <Row>
      <h4>Tab {index + 1}</h4>
      <Col md={6} xl={6} lg={6} xs={6} className="form-group">
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control 
            type="text" 
            value={group.title || ''}
            onChange={(e) => handleFieldChange(index, 'title', e.target.value)} 
          />
        </Form.Group>
      </Col>
      <Col md={8} xl={8} lg={8} xs={12} className="form-group">
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <QuillEditor
            value={group.description || ''}
            onChange={(content) => handleEditorChanged(index, 'description', content)}
          />
        </Form.Group>
      </Col>
      </Row>
                  <Row>
      <Col md={4} xl={4} lg={4} xs={12} className="form-group">
        <Form.Group>
          <Form.Label>Upload Thumbnail</Form.Label>
          <Form.Control 
            type="file" 
            onChange={(e) => handleThumbnailChange(e, index)} 
          />
        </Form.Group>
      </Col>
      <Col md={4} xl={4} lg={4} xs={12} className="form-group">
        <Form.Group>
          <Form.Label>Upload Video</Form.Label>
          <Form.Control 
            type="file" 
            onChange={(e) => handleVideoChange(e, index)} 
          />
          {group.video_URL && <video controls src={group.video_URL} style={{ marginTop: '10px', maxWidth: '100%' }} />}
        </Form.Group>
      </Col>
      </Row>
                    <Row>
      <Col md={4} xl={4} lg={4} xs={12} className="form-group">
        <Form.Group>
          <Form.Label>Upload Document</Form.Label>
          <Form.Control 
            type="file" 
            onChange={(e) => handleDocChange(e, index)} 
          />
          {group.pdf_URL && <a href={group.pdf_URL} target="_blank" rel="noopener noreferrer">View Document</a>}
        </Form.Group>
      </Col>
      </Row>
      <Row>
      <Col md={4} xl={4} lg={4} xs={12} className="form-group">
        <Form.Group>
          <Form.Label>Live Class Link</Form.Label>
          <Form.Control 
            type="url" 
            value={group.liveClassLink || ''}
            onChange={(e) => handleFieldChange(index, 'liveClassLink', e.target.value)} 
          />
        </Form.Group>
      </Col>
    </Row>
  </div>
))}
<Col md={4} xl={4} lg={4} xs={12} className="form-group">

                    <Button variant="secondary" onClick={handleAddGroup} className='secondary-btn'>Add Group</Button>
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    
                    <Button type="submit" disabled={uploading} className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >
                        {uploading ? 'Uploading...' : 'Create LMS'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />

    </DashboardContent>
  );
}

export default CreateLMS;