import React, { useEffect, useState } from "react";
import { get } from "./rest";
import DashboardContent from "./DashboardContent";
import { Card, CardBody, CardHeader, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import SelectField from "./SelectField";

function StudentsProgress() {
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [optionsStudents, setOptionsStudents] = useState([]);
  const [courses, setCourses] = useState([]);
  const [lessonEvents, setLessonEvents] = useState([]);
  const [assessmentEvents, setAssessmentEvents] = useState([]);
  const [assessmentNames, setAssessmentNames] = useState([]);
  const [courseEvents, setCourseEvents] = useState([]);

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get("users");
      const filteredStudents = response.response.filter(
        (student) =>
          student.role.slug === "student" &&
          student.organization &&
          student.organization.id === organizationid
      );
      const options = filteredStudents.map((student) => ({
        value: student.id,
        label: `${student.username} (${student.email})`,
      }));
      setOptionsStudents(options);
      setStudents(filteredStudents);
    } catch (error) {
      console.error("Error fetching Students options:", error);
    }
  };

  const fetchEvents = async (studentId) => {
    const organizationid = sessionStorage.getItem("organizationid");

    try {
      // Fetch lesson events
      const lessonResponse = await get("globalevents", {
        params: {
          type: "lessonevent",
          organization: organizationid,
          user: studentId,
        },
      });
      setLessonEvents(
        lessonResponse.response.filter(
          (event) => event.type === 'lessonevent'
        )
      );

      // Fetch assessment events
      const assessmentResponse = await get("globalevents", {
        params: {
          type: "assessmentevent",
          organization: organizationid,
          user: studentId,
        },
      });
      setAssessmentEvents(
        assessmentResponse.response.filter(
          (event) => event.type === 'assessmentevent' && event.user.id === studentId
        )
      );
      debugger;
      console.log('assessments:' + assessmentEvents )
      // Fetch course events
      const courseResponse = await get("globalevents", {
        params: {
          type: "courseevent",
          organization: organizationid,
          user: studentId,
        },
      });
      setCourseEvents(
        courseResponse.response.filter(
          (event) => event.type === 'courseevent'
        )
      );
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleStudentSelect = async (selectedValue) => {
    const studentId = selectedValue;
    setSelectedStudent(studentId);

    try {
      const studentCourses = await get("usercourseenrollment");
      setCourses(
        studentCourses.response.filter((course) => course.user.id === studentId)
      );

      // Fetch events after courses are fetched
      fetchEvents(studentId);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  const getLastEvent = (events) => {
    return events.reduce((latest, event) => {
      return new Date(event.createdAt) > new Date(latest.createdAt)
        ? event
        : latest;
    }, events[0]);
  };

  const getAssessmentNames = async (events) => {
    const names = [];
    for (const event of events) {
      try {
        const response = await get(`assessmentquiz/${event.index}`);
        names.push(response.response.title);
      } catch (error) {
        console.error("Error fetching assessment name:", error);
        names.push("Unknown Assessment");
      }
    }
    return names;
  };

  useEffect(() => {
    if (selectedStudent) {
      // Fetch assessment names when assessment events change
      getAssessmentNames(assessmentEvents).then((names) => {
        setAssessmentNames(names);
      });
    }
  }, [assessmentEvents]);

  return (
    <DashboardContent pageTitle="Students Progress">
      <div>
        <Row>
          <Col md={6} xl={6} lg={6} xs={12} >
            <Form.Group controlId="selectStudent" className="form-group">
              <Form.Label>Select Student:</Form.Label>
              <SelectField
                options={optionsStudents}
                onChange={handleStudentSelect}
                placeholder="Select a student"
              />
            </Form.Group>
          </Col>
        </Row>

        {selectedStudent && (
          <>
            <div className="mt-5">
              <h3>
              Topics -{" "}
                {
                  students.find((student) => student.id === selectedStudent)
                    ?.firstname
                }
              </h3>
              <Row>
                {courses.length > 0 ? (
                  courses.map((course, index) => (
                    <Col key={index} md={4} className="my-5">
                      <Link to={`/lms?id=${course.course.id}`}>
                        <Card className="flex-fill">
                          <CardHeader className="p-0">
                            {course.course.price > 0 && (
                              <i className="fa-brands fa-square-web-awesome-stroke premimum-icon"></i>
                            )}
                            <img
                              src={course.course.thumbnail}
                              alt={course.course.course_title}
                              className="img-fluid"
                              height="150px"
                            />
                          </CardHeader>
                          <CardBody>
                            <h3>{course.course.course_title}</h3>
                            {/* Display last seen lesson progress if available */}
                            {courseEvents.length > 0 ? (
                              <p>Progress: Course Completed</p>
                            ) : lessonEvents.length > 0 ? (
                              <p>Progress: {getLastEvent(lessonEvents).name}</p>
                            ) : (
                              <p>No progress recorded.</p>
                            )}
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                  ))
                ) : (
                  <Col md={12} className="text-center my-5">
                    <p>No Topics taken.</p>
                  </Col>
                )}
              </Row>
            </div>
            {/* <div className="mt-5">
              <h3>
                Assessments -{" "}
                {
                  students.find((student) => student.id === selectedStudent)
                    ?.firstname
                }
              </h3>
              <Row>
                {assessmentEvents.length > 0 ? (
                  assessmentEvents.map((event, index) => (
                    <Col key={index} md={4} className="my-5">
                      <Card className="flex-fill">
                        <CardBody>
                          <h3>{assessmentNames[index]}</h3>
                          <p>Progress: {event.name}</p>
                        </CardBody>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <Col md={12} className="text-center my-5">
                    <p>No assessments taken.</p>
                  </Col>
                )}
              </Row>
            </div> */}
          </>
        )}
      </div>
    </DashboardContent>
  );
}

export default StudentsProgress;
