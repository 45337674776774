import React, { useEffect, useState } from "react";
import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from "react-bootstrap";
import SelectField from "./SelectField";
import { ToastContainer, toast } from "react-toastify";
import { get, post, put } from "./rest";
import { useLocation } from "react-router-dom";
import CustomSelectField from "./CustomSelectField";
import PDFViewer from "./PDFViewer";
import UnitSelectField from "./UnitSelectField";
import QuillEditor from "./QuillEditor";

function EditLMS() {
  const authorid = sessionStorage.getItem("authorid");
  const [formData, setFormData] = useState({
    thumbnail_URL: "",
    lesson_title: "",
    lesson_description: "",
    metatitle:"",
    metadescription:"",
    course: "",
    category: "",
    courseModule: "",
    courseChapter: "",
    pdf_URL: "",
    video_URL: "",
  });
  const [groups, setGroups] = useState([{ title: '', description: '', thumbnail_URL: '', pdf_URL: '', video_URL: '', liveClassLink: '' }]);


  const [id, setId] = useState(null);
  const location = useLocation();
  const [optionsIndustries, setOptionsIndustries] = useState([]);
  const [optionsCourses, setOptionsCourse] = useState([]);
  const [optionsModuleCourses, setOptionsModuleCourse] = useState([]);
  const [optionsUnitCourses, setOptionsUnitCourse] = useState([]);
  const [selectedOptionCourses, setSelectedOptionCourses] = useState(null);
  const [selectedOptionIndustries, setSelectedOptionIndustries] = useState(null);
  const [selectedOptionModule, setSelectedOptionModule] = useState();
  const [selectedOptionUnit, setSelectedOptionUnit] = useState();
  const [thumbnailURL, setThumbnailURL] = useState('');
  const [videoURL, setVideoURL] = useState('');
  const [docURL, setDocURL] = useState('');
  const [uploading, setUploading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [newUnitFormData, setNewUnitFormData] = useState({
    title: '',
  });
  const [newModuleFormData, setNewModuleFormData] = useState({
    title: '',
  });
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setId(id);
    fetchLMS(id);
  }, [location.search]);
  useEffect(() => {
    fetchIndustriesOptions();
  }, []);

  const fetchIndustriesOptions = async () => {
    try {
      const response = await get('categories');
      const options = response.response.map(role => ({
        value: role.id,
        label: role.industry,
        isSelected: role.id === selectedOptionIndustries,
      }));
      setOptionsIndustries(options);
    } catch (error) {
      console.error('Error fetching Industries options:', error);
    }
  };
  var organizationid = sessionStorage.getItem("organizationid");

  const fetchCourseOptions = async (selectedOptionCourses) => {
    try {
      const response = await get('courses');
      const options = response.response
      .filter(course => course.organization === organizationid || !course.organization)
      .map(course => ({
        value: course.id,
        label: course.course_title,
        isSelected: course.id === selectedOptionCourses,

      }));
      setOptionsCourse(options);
    } catch (error) {
      console.error('Error fetching Courses options:', error);
    }
  };

  const handleThumbnailChange = async (event, index = null) => {
    setUploading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file);
    formData.append('path', 'default/lms/thumbnail');
  
    try {
      const response = await fetch('http://greenbugapi.adef.tech/upload', {
        method: 'POST',
        body: formData,
      });
  
      if (response.status === 201) {
        const data = await response.json();
        if (data.response.length > 0) {
          const url = data.response[0].url;
          if (index !== null) {
            setGroups(prevGroups => {
              const updatedGroups = [...prevGroups];
              updatedGroups[index] = {
                ...updatedGroups[index],
                thumbnail_URL: url
              };
              return updatedGroups;
            });
          } else {
            setThumbnailURL(url);
            setFormData(prevState => ({
              ...prevState,
              thumbnail_URL: url
            }));
          }
          toast.success('Thumbnail Uploaded successfully');
        }
      } else {
        toast.error('Failed to upload image');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Failed to upload image');
    } finally {
      setUploading(false);
    }
  };
  
  const handleDocChange = async (event, index = null) => {
    setUploading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file);
    formData.append('path', 'default/lms/Documents');
  
    try {
      const response = await fetch('http://greenbugapi.adef.tech/upload', {
        method: 'POST',
        body: formData,
      });
  
      if (response.status === 201) {
        const data = await response.json();
        if (data.response.length > 0) {
          const url = data.response[0].url;
          if (index !== null) {
            setGroups(prevGroups => {
              const updatedGroups = [...prevGroups];
              updatedGroups[index] = {
                ...updatedGroups[index],
                pdf_URL: url
              };
              return updatedGroups;
            });
          } else {
            setDocURL(url);
            setFormData(prevState => ({
              ...prevState,
              pdf_URL: url
            }));
          }
          toast.success('Document Uploaded successfully');
        }
      } else {
        toast.error('Failed to upload');
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setUploading(false);
    }
  };
  
  const handleVideoChange = async (event, index = null) => {
    setUploading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file);
    formData.append('path', 'default/lms/videos');
  
    try {
      const response = await fetch('http://greenbugapi.adef.tech/upload', {
        method: 'POST',
        body: formData,
      });
  
      if (response.status === 201) {
        const data = await response.json();
        if (data.response.length > 0) {
          const url = data.response[0].url;
          if (index !== null) {
            setGroups(prevGroups => {
              const updatedGroups = [...prevGroups];
              updatedGroups[index] = {
                ...updatedGroups[index],
                video_URL: url
              };
              return updatedGroups;
            });
          } else {
            setVideoURL(url);
            setFormData(prevState => ({
              ...prevState,
              video_URL: url
            }));
          }
          toast.success('Video Uploaded successfully');
        }
      } else {
        toast.error('Failed to upload');
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setUploading(false);
    }
  };
const handleChangeIndustries = (selectedOptionIndustries) => {
  setSelectedOptionIndustries(selectedOptionIndustries);
  setFormData(prevState => ({
    ...prevState,
    category: selectedOptionIndustries
  }));
};
const handleChangeCourses = (selectedOptionCourses) => {
  setSelectedOptionCourses(selectedOptionCourses);
  setFormData(prevState => ({
    ...prevState,
    course: selectedOptionCourses
  }));
  fetchModuleOptionsSelected(selectedOptionCourses);
  setOptionsUnitCourse([]);
};
const fetchModuleOptionsSelected = async (selectedCourseId) => {
  
  try {
    const response = await get(`coursemodule?course=${selectedCourseId}`);
    const options = response.response.map((module) => ({
      value: module.id,
      label: module.title,
      isSelected: module.id === formData.coursemodule?.id,
    }));
    setOptionsModuleCourse(options);
  } catch (error) {
    console.error('Error fetching Module options:', error);
  }
};
const fetchModuleOptionSelected = async (selectedCourseId, selectmodule) => {
  
  try {
    const response = await get(`coursemodule?course=${selectedCourseId}`);
    const options = response.response.map((module) => ({
      value: module.id,
      label: module.title,
      isSelected: module.id === selectmodule,
    }));
    setOptionsModuleCourse(options);
  } catch (error) {
    console.error('Error fetching Module options:', error);
  }
};
const fetchCourseUnitOptionSelected = async (selectedOptionModule, selectchapter) => {
  try {
    const response = await get(`coursechapter?courseModule=${selectedOptionModule}`);
    var organizationid = sessionStorage.getItem("organizationid");
    const options = response.response.map(unt => {
      if (unt.organization === organizationid) {
        return {
          value: unt.id,
          label: unt.title,
          isselected: unt.id === selectchapter
        };
      }
      // If the condition is not met, return null or undefined
      // (this is optional and depends on how you want to handle non-matching items)
      return null; // or undefined
    }).filter(Boolean); // This filters out null or undefined elements
    
    setOptionsUnitCourse(options);
  } catch (error) {
    console.error('Error fetching Courses options:', error);
  }
};
const fetchCourseUnitOptions = async (selectedOptionModule) => {
  try {
    const response = await get(`coursechapter?courseModule=${selectedOptionModule}`);
    var organizationid = sessionStorage.getItem("organizationid");
    const options = response.response.map(unt => {
      if (unt.organization === organizationid) {
        return {
          value: unt.id,
          label: unt.title
        };
      }
      // If the condition is not met, return null or undefined
      // (this is optional and depends on how you want to handle non-matching items)
      return null; // or undefined
    }).filter(Boolean); // This filters out null or undefined elements
    
    setOptionsUnitCourse(options);
  } catch (error) {
    console.error('Error fetching Courses options:', error);
  }
};
  const fetchLMS = async (id) => {
    try {
      get("lms/" + id)
        .then((response) => {
          debugger;
          setFormData(response.response);
          setSelectedOptionModule(response.response.coursemodule);
          setSelectedOptionIndustries(response.response.category);
          const selectedCourse = optionsCourses.find(course => course.value === response.response.course.id);
          console.log("Selected Course:", selectedCourse);
          setSelectedOptionCourses(response.response.course.id);
          fetchCourseOptions(response.response.course.id);
          fetchModuleOptionSelected(response.response.course.id, response.response.coursemodule);
          fetchCourseUnitOptionSelected(response.response.coursemodule, response.response.coursechapter);
          setGroups(response.response.tabData || [{}]);
        })
        .catch((error) => console.error("Error fetching data:", error));
    } catch (error) {
      console.error("Error fetching LMS:", error);
    }
  };

  const handleChangeModule = (selectedModule) => {
    // const selectedModule = event.target.value;
    setSelectedOptionModule(selectedModule); 
    setFormData(prevState => ({
      ...prevState,
      courseModule: selectedModule // Update formData with the selected module value
    }));
    fetchCourseUnitOptions(selectedModule);

  };

 
  const handleModuleSubmit = async (e, filter) => {
    e.preventDefault();
    const organizationid = sessionStorage.getItem("organizationid");
    // const courseId = selectedOptionCourses?.value;
    if (!selectedOptionCourses) {
      toast.error('Please select a course before creating a module');
      return;
    }
    debugger;
    try {
      debugger;
      const response = await post('/coursemodule', { title: filter, organization: organizationid, course: selectedOptionCourses, createdBy: authorid });
      if (response.statusCode === 201) {
        toast.success('New module added successfully', {
          onClose: () => {
            fetchCourseModuleOptionfilter(selectedOptionCourses, filter);

            setNewModuleFormData({
              title: '',
            });
          setSelectedOptionUnit(null);
handleChangeUnit(selectedOptionUnit);

          }
        });
      } else {
        toast.error('Failed to add new module');
      }
    } catch (error) {
      console.error('Error adding new module:', error);
      toast.error('Failed to add new module');
    }
  };
 

  const fetchCourseModuleOptionfilter = async (courseId, filter) => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`coursemodule?course=${courseId}`);
      const options = response.response
        .map((module) => {
          if (module.organization === organizationid) {
            return {
              value: module.id,
              label: module.title,
              isselected: module.title === filter
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsModuleCourse(options);
      const selectedModule = options.find(module => module.label === filter || module.value === filter);
  
      if (selectedModule) {
        debugger;
        setSelectedOptionModule(selectedModule.value); 
        setFormData(prevState => ({
          ...prevState,
          courseModule: selectedModule.value
        }));
      }
    } catch (error) {
      console.error('Error fetching Module options:', error);
    }
  };
  
  const fetchCourseUnitOptionfilter = async (moduleId, filter) => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`coursechapter?courseModule=${moduleId}`);
      const options = response.response
        .map((unit) => {
          if (unit.organization === organizationid) {
            return {
              value: unit.id,
              label: unit.title,
              isselected: unit.title === filter
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsUnitCourse(options);
      
      const selectedUnit = options.find(Unit => Unit.label === filter || Unit.value === filter);
  
      if (selectedUnit) {
        setSelectedOptionUnit(selectedUnit.value); 
        setFormData(prevState => ({
          ...prevState,
          courseChapter: selectedUnit.value
        }));
      }
    } catch (error) {
      console.error('Error fetching Unit options:', error);
    }
  };
  const handleUnitSubmit = async (e, filter) => {
    e.preventDefault();
    const organizationid = sessionStorage.getItem("organizationid");
    // const moduleId = selectedOptionModule?.value;
    if (!selectedOptionModule) {
      toast.error('Please select a Module before creating a Unit');
      return;
    }
    debugger;
    try {
      debugger;
      const response = await post('/coursechapter', { title: filter, organization: organizationid, courseModule: selectedOptionModule,createdBy: authorid });
      if (response.statusCode === 201) {
        toast.success('New Unit added successfully',{
          onClose: () => {
            setNewUnitFormData({
              title: '',
            });
            fetchCourseUnitOptionfilter(selectedOptionModule, filter);
          }
        });
      } else {
        toast.error('Failed to add new Unit');
      }
    } catch (error) {
      console.error('Error adding new Unit:', error);
      toast.error('Failed to add new Unit');
    }
  };
  const handleChangeUnit = (selectedUnit) => {
    setSelectedOptionUnit(selectedUnit); 
    setFormData(prevState => ({
      ...prevState,
      courseChapter: selectedUnit // Update formData with the selected Unit value
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleAddGroup = () => {
    setGroups([...groups, { description: '' }]);
  };

  const handleGroupChange = (index, field, value) => {
    const updatedGroups = [...groups];
    updatedGroups[index] = {
      ...updatedGroups[index],
      [field]: value,
    };
    setGroups(updatedGroups);
  };

  const handleRemoveGroup = (index) => {
    const updatedGroups = [...groups];
    updatedGroups.splice(index, 1);
    setGroups(updatedGroups);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreating(true);

    try {
      const updatedFormData = {
        ...formData,
        thumbnail_URL: thumbnailURL || formData.thumbnail_URL,
        video_URL: videoURL || formData.video_URL,
        pdf_URL: docURL || formData.pdf_URL,
        tabData: groups,
        updatedBy: authorid
      };

      const responseData = await put("lms/" + id, updatedFormData);
      debugger;
      if (responseData.statusCode === 200) {
        toast.success("Updated Successfully", {
          onClose: () => {
            window.location.href = "/listoflms";
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setCreating(false);
    }
  };
  const handleFieldChange = (index, field, value) => {
    const updatedGroups = [...groups];
    updatedGroups[index] = {
      ...updatedGroups[index],
      [field]: value,
    };
    setGroups(updatedGroups);
  };
  const handleEditorChanged = (index, name, content) => {
    debugger;
    console.log("Index:", index);
    console.log("Field Name:", name);
    console.log("Content:", content);
  
    
      // Update group description in groups array
      setGroups(prevGroups => {
        const updatedGroups = [...prevGroups];
        updatedGroups[index] = {
          ...updatedGroups[index],
          [name]: content
        };
        return updatedGroups;
      });

  };
  const handleEditorChange = (name, content) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: content,
    }));
  };
  return (
    <DashboardContent pageTitle="Edit LMS">
      <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3> Edit LMS </h3>
                </div>
              </div>
              <div className="student-info">
                <Form onSubmit={handleSubmit} id="editemp" className="mt-5">
                <Row>
                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Categories:</Form.Label>
                      <SelectField options={optionsIndustries} placeholder="Select Categories" onChange={handleChangeIndustries} selected={formData.category.id}/>
                    </Col>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Course:</Form.Label>
                      <SelectField options={optionsCourses} placeholder="Select Course" onChange={handleChangeCourses} selected={formData.course._id} />
                    </Col>
                    </Row>
                  <Row>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      {/* <Form.Label htmlFor="module">Module:</Form.Label> */}
                      <CustomSelectField options={optionsModuleCourses} placeholder="Select Module" onChange={handleChangeModule} selected={selectedOptionModule} isSearchable handleModuleSubmit={handleModuleSubmit}/>
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      {/* <Form.Label htmlFor="unit">Unit:</Form.Label> */}
                      <UnitSelectField  options={optionsUnitCourses} placeholder="Select Unit" onChange={handleChangeUnit} selected={formData.courseChapter} isSearchable handleUnitSubmit={handleUnitSubmit}/>
                    </Col>
                    </Row>
                  <Row>
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Form.Label htmlFor="lesson_title">Lesson Title:</Form.Label>
                    <Form.Control type="text" id="lesson_title" name="lesson_title" className="form-control" onChange={handleChange} value={formData.lesson_title} required />
                  </Col>
                  </Row>
                  <Row>
                  <Col md={8} xl={8} lg={8} xs={12} className="form-group">
                    <Form.Label htmlFor="lesson_description">Lesson Description:</Form.Label>
                    {/* <textarea type="text" id="lesson_description" name="lesson_description" className="form-control" value={formData.lesson_description} onChange={handleChange} required ></textarea> */}
                    <QuillEditor
                          name="lesson_description"
                          value={formData.lesson_description}
                          onChange={(content) =>
                            handleEditorChange("lesson_description", content)
                          }
                        />
                  </Col>
                  </Row>
                  <Row>
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Form.Label htmlFor="metatitle">Meta Title:</Form.Label>
                    <Form.Control type="text" id="metatitle" name="metatitle" className="form-control" value={formData.metatitle} onChange={handleChange} required />
                  </Col>
                  </Row>
                  <Row>
                  <Col md={8} xl={8} lg={8} xs={12} className="form-group">
                    <Form.Label htmlFor="metadescription">Meta Description:</Form.Label>
                    {/* <textarea type="text" id="metadescription" name="metadescription" className="form-control" value={formData.metadescription} onChange={handleChange} required ></textarea> */}
                    <QuillEditor
                          name="metadescription"
                          value={formData.metadescription}
                          onChange={(content) =>
                            handleEditorChange("metadescription", content)
                          }
                        />
                  </Col>
                  </Row>
                  <Row>
                    {/* <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label htmlFor="docfile">PDF Document:</Form.Label>
                      <div className="wrapper">
                        <div className="box">
                          <div className="js--image-preview"></div>
                          <div className="upload-options">
                            <Form.Label>
                              <Form.Control type="file" id="docfile" className="doc-upload" accept="doc/*" onChange={handleDocChange}  />
                            </Form.Label>
                            {formData.pdf_URL && <a href={formData.pdf_URL}>Existing PDF Link</a>}

                            {/* <PDFViewer url={formData.pdfURL}></PDFViewer> */}
                          {/* </div>
                        </div>
                      </div>
                    </Col>  */}
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label >Thumbnail:</Form.Label>
                      <div className="wrapper">
                        <div className="box">
                          <div className="js--image-preview"></div>
                          <div className="upload-options">
                              <Form.Control type="file" id="imgfile" className="image-upload mb-3" accept="image/*" onChange={handleThumbnailChange}  />
                            {formData.thumbnail_URL && <img src={formData.thumbnail_URL} alt="thumnail" width={100}></img>}

                          </div>
                        </div>
                      </div>
                    </Col></Row>
                    <Row>
                    {/* <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label htmlFor="videofile">Video:</Form.Label>
                      <div className="wrapper">
                        <div className="box">
                          <div className="js--image-preview"></div>
                          <div className="upload-options">
                            <Form.Label>
                              <Form.Control type="file" id="videofile" className="video-upload" accept="video/*" onChange={handleVideoChange} />
                            </Form.Label>
                            {formData.video_URL && <a href={formData.video_URL}>Existing Video Link</a>}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                        <Form.Label htmlFor="liveclass">Live Class Link:</Form.Label>
                        <Form.Control type="url" id="liveclass" name="liveclass" className="form-control" value={formData.liveclass} onChange={handleChange} />
                    </Col> */}
                    {groups.map((group, index) => (
  <div key={index} className="group my-4">
    <Row>
      <h4>Tab {index + 1}</h4>
      <Col md={6} xl={6} lg={6} xs={12} className="form-group">
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            value={group.title || ''}
            onChange={(e) => handleGroupChange(index, 'title', e.target.value)}
          />
        </Form.Group>
      </Col>

      </Row>
      <Row>
      <Col md={12} xl={12} lg={12} xs={12} className="form-group">
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <QuillEditor
            value={group.description || ''}
            onChange={(content) => handleEditorChanged(index, 'description', content)}
          />
        </Form.Group>
      </Col>
      </Row>
      <Row>
      <Col md={4} xl={4} lg={4} xs={12} className="form-group">
        <Form.Group>
          <Form.Label>Upload Thumbnail</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => handleThumbnailChange(e, index)}
          />
          {group.thumbnail_URL && <img src={group.thumbnail_URL} alt="Thumbnail" width={100} />}
        </Form.Group>
      </Col>
      <Col md={4} xl={4} lg={4} xs={12} className="form-group">
        <Form.Group>
          <Form.Label>Upload Document</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => handleDocChange(e, index)}
          />
          {group.pdf_URL && <a href={group.pdf_URL}>Existing Document Link</a>}
        </Form.Group>
      </Col>
      </Row>
                  <Row>
      <Col md={4} xl={4} lg={4} xs={12} className="form-group">
        <Form.Group>
          <Form.Label>Upload Video</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => handleVideoChange(e, index)}
          />
          {group.video_URL && <a href={group.video_URL}>Existing Video Link</a>}
        </Form.Group>
      </Col>
      </Row>
                  <Row>
      <Col md={4} xl={4} lg={4} xs={12} className="form-group">
        <Form.Group>
          <Form.Label>Live Class Link</Form.Label>
          <Form.Control 
            type="url" 
            value={group.liveClassLink || ''}
            onChange={(e) => handleFieldChange(index, 'liveClassLink', e.target.value)} 
          />
        </Form.Group>
      </Col>
      </Row>
                  <Row>
      <Col md={12}>
        <Button variant="danger" onClick={() => handleRemoveGroup(index)} className="mt-3">Remove Tab</Button>
      </Col>
    </Row>
  </div>
))}
</Row>
<Row>
<Col md={4} xl={4} lg={4} xs={12} className="form-group">
      <Button onClick={handleAddGroup} className="secondary-btn">Add Group</Button>
      </Col>
                    </Row>
                    <Row>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Button type="submit" disabled={uploading} className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >
                        {uploading ? 'Uploading...' : 'Update LMS'}
                      </Button>
                      <p>{creating ? 'Updating' : ''}</p>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </DashboardContent>
  );
}

export default EditLMS;
