import React, { useEffect, useState } from "react";
import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { get, post, put } from "./rest";
import { useLocation } from "react-router-dom";
import SelectField from "./SelectField";
import SelectFieldQuiz from "./SelectFieldQuiz";
import CustomSelectField from "./CustomSelectField";
import UnitSelectField from "./UnitSelectField";
import QuillEditor from "./QuillEditor";

function EditFillintheBlank() {
  const authorid = sessionStorage.getItem("authorid");
  const [formData, setFormData] = useState({
  question: "", 
  course: {
    id: "", 
    course_title: "" 
  },
  coursemodule: {
    id: "", 
    title: "" 
  },
  coursechapter: {
    id: "", 
    title: "" 
  },
  lms:{
    id:"",
    lesson_title:""
  },
  assessmentQuiz: {
    id: "", 
    title: "" 
  }
});

  const [id, setId] = useState(null);
  const location = useLocation();
  const [optionsCourses, setOptionsCourse] = useState([]);
  const [selectedOptionCourses, setSelectedOptionCourses] = useState(null);
  const [selectedAssessment, setselectedAssessment] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionsModuleCourse, setOptionsModuleCourse] = useState([]); // State for module options
  const [selectedOptionModule, setSelectedOptionModule] = useState(null); // State for selected module
  const [optionsUnitModule, setOptionsUnitModule] = useState([]); 
  const [optionsUnitCourses, setOptionsUnitCourse] = useState([]);
  const [optionsLessonUnit, setOptionsLessonUnit] = useState([]); // State for module options
  const [selectedOptionLesson, setSelectedOptionLesson] = useState(null); // State for selected unit
 
  const [selectedOptionUnit, setSelectedOptionUnit] = useState(null);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState(null);
  const [existingAssessments, setExistingAssessments] = useState([]); 
  const [newQuizFormData, setNewQuizFormData] = useState({
    title: '',
    description:''
  });
  const [newModuleFormData, setNewModuleFormData] = useState({
    title: '',
  });
  const [newUnitFormData, setNewUnitFormData] = useState({
    title: '',
  });
  const [newLessonFormData, setLessonFormData] = useState({
    title: '',
  });
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setId(id);
    fetchAssessments(id);
    fetchCourseOptions();
  }, [location.search]);
  const fetchAssessments = async (id) => {
    try {
      const response = await get("assessment/" + id);
      const responseData = response.response;
      const { course, coursemodule, coursechapter,lms, assessmentQuiz, ...otherData } = responseData;
  
      // Update course, coursemodule, and coursechapter if they exist
      const updatedFormData = {
        ...otherData,
        course: course ? { id: course } : null,
        coursemodule: coursemodule ? { id: coursemodule } : null,
        coursechapter: coursechapter ? { id: coursechapter } : null,
        lms: lms ? { id: lms } : null,
        assessmentQuiz: assessmentQuiz ? { id: assessmentQuiz } : null,
      };
      debugger;
      setFormData(updatedFormData);
      setSelectedOptionCourses(course ? { value: course.id, label: course.course_title } : null);
      setSelectedOptionModule(coursemodule ? { value: coursemodule.id, label: coursemodule.title } : null);
      setSelectedOptionLesson(lms);
      setSelectedOptionUnit(coursechapter ? { value: coursechapter.id, label: coursechapter.title } : null);
      setSelectedAssessmentId(assessmentQuiz ? { value: assessmentQuiz.id, label: assessmentQuiz.title } : null);

      fetchModuleOptionSelected(course?.id, coursemodule?.id);
      fetchCourseUnitOptionSelected(coursemodule?.id, coursechapter?.id);
      fetchCourseLessonOption(coursechapter?.id);
      fetchExistingAssessments(course?.id);
    } catch (error) {
      console.error("Error fetching Questions:", error);
    }
  };
  const handleChangeCourses = (selectedOptionCourses) => {
    setSelectedOptionCourses(selectedOptionCourses);
    setFormData(prevState => ({
      ...prevState,
      course: selectedOptionCourses
    }));
    fetchModuleOptionsSelected(selectedOptionCourses);
    setOptionsUnitModule([]);
    fetchExistingAssessments(selectedOptionCourses);
  };
  const fetchModuleOptionsSelected = async (selectedCourseId) => {
    
    try {
      const response = await get(`coursemodule?course=${selectedCourseId}`);
      const options = response.response.map((module) => ({
        value: module.id,
        label: module.title,
        isSelected: module.id === formData.coursemodule?.id,
      }));
      debugger;
      setOptionsModuleCourse(options);
    } catch (error) {
      console.error('Error fetching Module options:', error);
    }
  };
  const fetchModuleOptionSelected = async (selectedCourseId, selectmodule) => {
  debugger;
    try {
      const response = await get(`coursemodule?course=${selectedCourseId}`);
      const options = response.response.map((module) => ({
        value: module.id,
        label: module.title,
        isSelected: module.id === selectmodule,
      }));
      setOptionsModuleCourse(options);
      const selectedModule = options.find(uni => uni.label === selectmodule || uni.value === selectmodule);
  
      if (selectedModule) {
        debugger;
        setSelectedOptionModule(selectedModule.value); 
        setFormData(prevState => ({
          ...prevState,
          coursemodule: selectedModule.value
        }));
      }
      fetchUnitOptionsSelected(selectedModule.value);
      debugger;
    } catch (error) {
      console.error('Error fetching Module options:', error);
    }
  };
  const fetchCourseUnitOptionSelected = async (selectedOptionModule, selectchapter) => {
    debugger;
    try {
      const response = await get(`coursechapter?courseModule=${selectedOptionModule}`);
      var organizationid = sessionStorage.getItem("organizationid");
      const options = response.response.map(unt => {
        if (unt.organization === organizationid) {
          return {
            value: unt.id,
            label: unt.title,
            isselected: unt.id === selectchapter
          };
        }
        // If the condition is not met, return null or undefined
        // (this is optional and depends on how you want to handle non-matching items)
        return null; // or undefined
      }).filter(Boolean); // This filters out null or undefined elements
      
      setOptionsUnitCourse(options);
      const selectedModule = options.find(uni => uni.label === selectchapter || uni.value === selectchapter);
  
      if (selectedModule) {
        debugger;
        setSelectedOptionUnit(selectedModule.value); 
        setFormData(prevState => ({
          ...prevState,
          coursechapter: selectedModule.value
        }));
      }
      debugger;
    } catch (error) {
      console.error('Error fetching Courses options:', error);
    }
  };
  // const fetchModuleOptions = async () => {
    
  //   try {
  //     const response = await get('coursemodule');
  //     const options = response.response.map((module) => ({
  //       value: module.id,
  //       label: module.title,
  //     }));
  //     setOptionsModuleCourse(options);
  //   } catch (error) {
  //     console.error('Error fetching Module options:', error);
  //   }
  // };
  
  // Handle module change
  const handleChangeModule = (selectedOptionModule) => {
    debugger;
    // const selectedOptionModule = event.target.value;
    setSelectedOptionModule(selectedOptionModule);
    setFormData((prevState) => ({
      ...prevState,
      coursemodule: selectedOptionModule,
    }));
    fetchUnitOptionsSelected(selectedOptionModule)
  };
  const fetchUnitOptionsSelected = async (selectedModuleId) => {
    
    try {
      const response = await get(`coursechapter?courseModule=${selectedModuleId}`);
      debugger;
      const options = response.response.map((unit) => ({
        value: unit.id,
        label: unit.title,
        isSelected: unit.id === formData.coursechapter?.id,
      }));
      debugger;
      setOptionsUnitModule(options);
    } catch (error) {
      console.error('Error fetching Unit options:', error);
    }
  };
  const fetchUnitOptions = async (selectedModuleId) => {
    
    try {
      const response = await get(`coursechapter?courseModule=${selectedModuleId}`);
      const options = response.response.map((unit) => ({
        value: unit.id,
        label: unit.title,
      }));
      setOptionsUnitModule(options);
    } catch (error) {
      console.error('Error fetching Unit options:', error);
    }
  };
    
  // Handle unit change
  const handleChangeUnit = (selectedOptionUnit) => {
    // const selectedOptionUnit = event.target.value;
    setSelectedOptionUnit(selectedOptionUnit);
    setFormData((prevState) => ({
      ...prevState,
      coursechapter: selectedOptionUnit,
    }));
    fetchCourseLessonOption(selectedOptionUnit);
  };
  const fetchExistingAssessments = async (selectedOptionCourses) => {
    debugger;
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`assessmentquiz`);
      const assessments = response.response
        .filter((assessment) => assessment.organization === organizationid)
        .map((assessment) => ({
          value: assessment.id,
          label: assessment.title,
          isSelected: assessment.id === formData.assessmentQuiz?.id,
        }));
      debugger;
      setExistingAssessments(assessments);
    } catch (error) {
      console.error('Error fetching existing assessments:', error);
    }
    
  };
  // const fetchExistingAssessments = async () => {
  //   try {
  //     const response = await get('assessmentquiz');
      
  //     const assessments = response.response.map(assessment => ({
  //       value: assessment.id,
  //       label: assessment.title,
  //     }));
  //     setExistingAssessments(assessments);
  //   } catch (error) {
  //     console.error('Error fetching existing assessments:', error);
  //   }
  // };
  const fetchCourseOptions = async () => {
    const organizationid = sessionStorage.getItem("organizationid");

    try {
      const response = await get('courses');
      const options = response.response.filter(cours=> cours.organization && cours.organization === organizationid ).map(course => ({
        value: course.id,
        label: course.course_title,
      }));
      setOptionsCourse(options);
    } catch (error) {
      console.error('Error fetching Courses options:', error);
    }
  };
  const fetchCourseLessonOption = async (selectedOptionUnit) => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`lms?coursechapter=${selectedOptionUnit}`);
      const options = response.response.filter(lms => lms.coursechapter && lms.coursechapter === selectedOptionUnit)
        .map((lesson) => {
          debugger;
          if (lesson.organization === organizationid) {
            return {
              value: lesson.id,
              label: lesson.lesson_title,
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsLessonUnit(options);
    } catch (error) {
      console.error("Error fetching Module options:", error);
    }
  };
  const handleChangeLesson = (selectedLesson) => {
    debugger;
    // const selectedLesson = event.target.value;

    setSelectedOptionLesson(selectedLesson);
    setFormData((prevState) => ({
      ...prevState,
      lms: selectedLesson,
    }));
  };
  const handleAssessmentChange = (selectedAssessment) => {
    
    setSelectedAssessmentId(selectedAssessment.value);
    setFormData((prevState) => ({
      ...prevState,
      assessmentQuiz: selectedAssessment,
    }));
  };
  const handleQuizSubmit = () => {
    fetchExistingAssessments();
  }; 
  
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleModuleSubmit = async (e, filter) => {
    e.preventDefault();
    const organizationid = sessionStorage.getItem("organizationid");
    // const courseId = selectedOptionCourses?.value;
    if (!selectedOptionCourses) {
      toast.error('Please select a course before creating a module');
      return;
    }
    debugger;
    try {
      debugger;
      const response = await post('/coursemodule', { title: filter, organization: organizationid, course: selectedOptionCourses, createdBy: authorid });
      if (response.statusCode === 201) {
        toast.success('New module added successfully', {
          onClose: () => {
            fetchCourseModuleOptionfilter(selectedOptionCourses, filter);

            setNewModuleFormData({
              title: '',
            });
          setSelectedOptionUnit(null);
handleChangeUnit(selectedOptionUnit);

          }
        });
      } else {
        toast.error('Failed to add new module');
      }
    } catch (error) {
      console.error('Error adding new module:', error);
      toast.error('Failed to add new module');
    }
  };
 

  const fetchCourseModuleOptionfilter = async (courseId, filter) => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`coursemodule?course=${courseId}`);
      const options = response.response
        .map((module) => {
          if (module.organization === organizationid) {
            return {
              value: module.id,
              label: module.title,
              isselected: module.title === filter
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsModuleCourse(options);
      const selectedModule = options.find(module => module.label === filter || module.value === filter);
  
      if (selectedModule) {
        debugger;
        setSelectedOptionModule(selectedModule.value); 
        setFormData(prevState => ({
          ...prevState,
          coursemodule: selectedModule.value
        }));
      }
    } catch (error) {
      console.error('Error fetching Module options:', error);
    }
  };
  
  const fetchCourseUnitOptionfilter = async (moduleId, filter) => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`coursechapter?courseModule=${moduleId}`);
      const options = response.response
        .map((unit) => {
          if (unit.organization === organizationid) {
            return {
              value: unit.id,
              label: unit.title,
              isselected: unit.title === filter
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsUnitModule(options);
      
      const selectedUnit = options.find(Unit => Unit.label === filter || Unit.value === filter);
  
      if (selectedUnit) {
        setSelectedOptionUnit(selectedUnit.value); 
        setFormData(prevState => ({
          ...prevState,
          coursechapter: selectedUnit.value
        }));
      }
    } catch (error) {
      console.error('Error fetching Unit options:', error);
    }
  };
  const handleUnitSubmit = async (e, filter) => {
    e.preventDefault();
    const organizationid = sessionStorage.getItem("organizationid");
    // const moduleId = selectedOptionModule?.value;
    if (!selectedOptionModule) {
      toast.error('Please select a Module before creating a Unit');
      return;
    }
    debugger;
    try {
      debugger;
      const response = await post('/coursechapter', { title: filter, organization: organizationid,coursemodule: selectedOptionModule, createdBy: authorid  });
      if (response.statusCode === 201) {
        toast.success('New Unit added successfully',{
          onClose: () => {
            setNewUnitFormData({
              title: '',
            });
            fetchCourseUnitOptionfilter(selectedOptionModule, filter);
          }
        });
      } else {
        toast.error('Failed to add new Unit');
      }
    } catch (error) {
      console.error('Error adding new Unit:', error);
      toast.error('Failed to add new Unit');
    }
  };
  const handleEditorChange = (name, content) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: content,
    }));
  };
  const handleSubmit = async (e) => {
    debugger;
    e.preventDefault();
    
    const updatedForm = {
      ...formData,
      course: formData.course.id !== undefined ? formData.course.id.id : formData.course, 
      coursemodule: formData.coursemodule.id !== undefined ? formData.coursemodule.id.id : formData.coursemodule,
      coursechapter: formData.coursechapter.id !== undefined ? formData.coursechapter.id.id : formData.coursechapter,
      assessmentQuiz: formData.assessmentQuiz.id !== undefined ? formData.assessmentQuiz.id.id : formData.assessmentQuiz,
      updatedBy : authorid
    };
    try {
    // const { name, value } = e.target;

      const responseData = await put("assessment/" + id, updatedForm);
      
      if (responseData.statusCode === 200) {
        toast.success("Updated Successfully", {
          onClose: () => {
            window.location.href = "/questionbank";
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <DashboardContent pageTitle="Edit Question">
      <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3> Edit Question </h3>
                </div>
              </div>
              <div className="student-info hidecheckox">
                <Form
                  onSubmit={handleSubmit}
                  id="editQuestion"
                  className="mt-5"
                >
                  <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Course:</Form.Label>
                      <SelectField
                        options={optionsCourses}
                        placeholder="Select Course"
                        onChange={handleChangeCourses}
                        selected={selectedOptionCourses}
                      />
                    </Col>
                    </Row>
                    <Row>
                        <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                          {/* <Form.Label>Module:</Form.Label> */}
                          {/* <SelectField options={optionsModuleCourse} placeholder="Select Module" onChange={handleChangeModule} selected={formData.module.id} /> */}
                          <CustomSelectField options={optionsModuleCourse} placeholder="Select Module" onChange={handleChangeModule} selected={selectedOptionModule} isSearchable handleModuleSubmit={handleModuleSubmit}/>
                        </Col>
                        <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                          {/* <Form.Label>Unit:</Form.Label> */}
                          <UnitSelectField  options={optionsUnitModule} placeholder="Select Unit" onChange={handleChangeUnit} selected={selectedOptionUnit} isSearchable handleUnitSubmit={handleUnitSubmit}/>
                        </Col>
                        {selectedOptionUnit && ( // Render unit select box only if module is selected */} */}
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Form.Label>Lesson:</Form.Label>
                    <SelectField options={optionsLessonUnit} placeholder="Select Lesson" onChange={handleChangeLesson} selected={selectedOptionLesson}/>
                    {/* <UnitSelectField  options={optionsUnitModule} placeholder="Select Unit" onChange={handleChangeUnit} selected={formData.coursechapter} isSearchable handleUnitSubmit={handleUnitSubmit}/> */}
                  </Col>
                 )} 
                <Col md={12} xl={12} lg={12} xs={12} className="form-group">
                 <Form.Label>Assessment Title:</Form.Label>
                 <SelectFieldQuiz
                    options={existingAssessments}
                    placeholder="Select Assessment" 
                    handleAssessmentChange={handleAssessmentChange}
                    selected={selectedAssessmentId} // Ensure this prop matches the selected value
                    selectedOptionCourses={selectedOptionCourses}
                    selectedOptionModule={selectedOptionModule}
                    selectedOptionUnit={selectedOptionUnit}
                    selectedOptionLesson={selectedOptionLesson}
                    isSearchable
                    handleQuizSubmit={handleQuizSubmit}
                  />

                 </Col>
                    <Col md={12} className="form-group">
                        <Form.Group controlId="question">
                            <Form.Label>Question</Form.Label>
                            <QuillEditor
                          name="question"
                          value={formData.question}
                          placeholder="Enter the question with a blank (e.g., The capital of France is ___)"
                          onChange={(content) =>
                            handleEditorChange("question", content)
                          }
                        />
                            {/* <Form.Control
                            as="textarea"
                            rows={3}
                            name="question"
                            value={formData.question}
                            placeholder="Enter the question with a blank (e.g., The capital of France is ___)"
                            onChange={handleChange}
                            /> */}
                        </Form.Group>
                    </Col>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                  <Form.Group className="form-group">
                  <Form.Label>Difficulty Level</Form.Label>
                  <Form.Control
                    as="select"
                    value={formData.difficultyLevel}
                    onChange={(e) => setFormData({ ...formData, difficultyLevel: e.target.value })}
                    required
                  >
                    <option value="">Select Difficulty Level</option>
                    {[...Array(11).keys()].map((level) => (
                      <option key={level} value={level}>
                        {level}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                </Col>
                    </Row>
                    <Row>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Button
                        type="submit"
                        className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      >
                        Update Question
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </DashboardContent>
  );
}

export default EditFillintheBlank;
