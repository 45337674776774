import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import DOMPurify from 'dompurify';

function ViewResultModal({ show, handleClose, OrgAdmin }) {
  if (!OrgAdmin) return null;

  const { answers } = OrgAdmin;

  const renderAnswers = () => {
    return Object.keys(answers).map((key) => {
      if (Array.isArray(answers[key])) {
        // If answers[key] is an array, render each answer in the array
        return answers[key].map((ans, index) => (
          <tr key={`${key}_${index}`}>
            <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ans.questionTitle) }}></td>
            <td>{ans.selectedOption.join(', ')}</td>
            <td>{ans.isCorrect ? 'Correct' : 'Wrong'}</td>
          </tr>
        ));
      } else {
        // If answers[key] is an object, render the single answer
        const ans = answers[key];
        return (
          <tr key={key}>
            <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ans.questionTitle) }}></td>
            <td>{ans.selectedOption.join(', ')}</td>
            <td>{ans.isCorrect ? 'Correct' : 'Wrong'}</td>
          </tr>
        );
      }
    });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>View Result</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Question</th>
              <th>Selected Answer</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {renderAnswers()}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className='secondary-btn' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewResultModal;
