import { useState } from "react";
import "./Admin.css";
import { Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { post } from "./rest";

function LoginSuperAdmin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [response, setResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleSubmit  = async (e) => {
    e.preventDefault();
    const payload = {
      email: email,
      password: password
    };
    const responseData = await post('auth/login', payload);

    // Check if there's a user with the entered email
      // Check if the entered password matches the user's password
      if(responseData.statusCode === 201){
        if(responseData.response._id){
          sessionStorage.setItem("authorid", responseData.response._id);
          sessionStorage.setItem("authorname", responseData.response.firstname);
          sessionStorage.setItem("authoremail", responseData.response.email);
          sessionStorage.setItem("roleid", responseData.response.role._id);
          sessionStorage.setItem("roleslug", responseData.response.role.slug);
          sessionStorage.setItem("rolename", responseData.response.role.role);
          sessionStorage.setItem("organizationid", responseData.response.organization._id);
          sessionStorage.setItem("logo", responseData.response.organization.logo);
          sessionStorage.setItem("organizationname", responseData.response.organization.adminOrganization);
          debugger;
          toast.success("Login Successful", {
            onClose: () => {
              window.location.href='/dashboard';
            },
          });
        }
        else{
         
          toast.error("Failed, Check again");
        }
      } else {
        console.log('Incorrect password');
        toast.error("Failed, Check again");
      }
  };

  return (
    <>
      <div className="login-page-wrap">
        <div className="login-page-content">
          <div className="login-box">
            <div className="item-logo">
              <p className="menulogoonlog">
                Login
              </p>
            </div>
            <Form id="salogin" className="form-box" onSubmit={handleSubmit}>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  placeholder="Enter Email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="form-group" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  className="form-control"
                  type="password"
                  placeholder="Enter password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="form-group d-flex text-left">
                <a href="/forgotpassword" className="forgot-btn ">
                  Forgot Password?
                </a>                
              </Form.Group>

              <Button variant="primary" type="submit" className="login-btn">
                Login
              </Button>
              <Form.Group className="form-group text-center mt-4">
                <a href="/signup" className="forgot-btn ">
                  Sign up as Student
                </a>                
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default LoginSuperAdmin;
