// rest.js
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';

// Define the base URL of your REST API
const BASE_URL = "http://greenbugapi.adef.tech/";

// Function to handle HTTP GET requests
async function get(endpoint) {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

// Function to handle HTTP POST requests
async function post(endpoint, body) {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
}

// Function to handle HTTP PUT requests
async function put(endpoint, body) {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating data:', error);
    throw error;
  }
}

// Function to handle HTTP DELETE requests
async function del(endpoint) {
  try {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    });

    if (confirmed.isConfirmed) {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'DELETE'
      });
      const data = await response.json();
      if (data.statusCode === 200){
        Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
        return data;
      }
     
    } else if (confirmed.dismiss === Swal.DismissReason.cancel) {
      Swal.fire('Cancelled', 'Your item is safe :)', 'info');
      return null;
    }
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
}

// Function to handle HTTP POST requests with FormData
async function postFormData(endpoint, formData) {
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  }
// Export the functions for use in your React components
export { get, post, put, del, postFormData };
