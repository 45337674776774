import DOMPurify from "dompurify";
import { Card, Row, Button, Col } from "react-bootstrap";
import { get, post, put } from "./rest";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AssessmentView({ selectedCourse, selectedModule, selectedChapter }) {
  const [assessmentsData, setAssessmentsData] = useState([]);
  const [detailedAssessments, setDetailedAssessments] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [correctAnswers, setCorrectAnswers] = useState({});
  const [correctPercentage, setCorrectPercentage] = useState(0);
  const [hasFillInTheBlanks, setHasFillInTheBlanks] = useState(false);
  const [currentAssessmentIndex, setCurrentAssessmentIndex] = useState(-1);
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [timeLeft, setTimeLeft] = useState(null);
  const [assessmentReportId, setAssessmentReportId] = useState(null);
  const [selectedassessmentId, setSelectedAssessmentId] = useState(null);
  const [allAnswers, setAllAnswers] = useState({}); // Define allAnswers state
  const [assessmentIdPost, setAssessmentIdPost] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [eventsPosted, setEventsPosted] = useState({});
  const prevAssessmentIndexRef = useRef();

  useEffect(() => {
    if (selectedCourse) {
      if (selectedModule && selectedChapter) {
        fetchAssessments(selectedCourse, selectedModule, selectedChapter);
      } else if (selectedModule) {
        fetchAssessments(selectedCourse, selectedModule);
      } else {
        fetchAssessments(selectedCourse);
      }
    }
  }, [selectedCourse, selectedModule, selectedChapter]);
    // Use ref to store previous assessment index to avoid hitting multiple times
    useEffect(() => {
      if (currentAssessmentIndex !== -1 && prevAssessmentIndexRef.current !== currentAssessmentIndex) {
        const assessment = assessmentsData[currentAssessmentIndex];
        debugger;
        if (assessment && !eventsPosted[assessment.id]) {
          post('globalevents', {
            name: `Assessment Started`,
            type: "assessmentevent",
            createdAt: new Date(),
            organization: sessionStorage.getItem("organizationid"),
            user: sessionStorage.getItem("authorid"),
            index: assessment.id,
          });
          setEventsPosted((prevEventsPosted) => ({
            ...prevEventsPosted,
            [assessment.id]: true,
          }));
        }
        prevAssessmentIndexRef.current = currentAssessmentIndex;
      }
    }, [currentAssessmentIndex, assessmentsData, eventsPosted]);
  
  const fetchAssessments = async (courseId, moduleId = null, chapterId = null) => {
    try {
      const response = await get(`assessmentquiz?course=${courseId}`);
      let filteredAssessments = response.response;

      if (moduleId) {
        filteredAssessments = filteredAssessments.filter(item => item.coursemodule === moduleId);
      }

      if (chapterId) {
        filteredAssessments = filteredAssessments.filter(item => item.coursechapter === chapterId);
      }

      setAssessmentsData(filteredAssessments);

      if (filteredAssessments.length > 0) {
        const detailedDataPromises = filteredAssessments.map(assessment =>
          get(`assessment?assessmentQuiz=${assessment.id}`)
        );
        

        const detailedDataResponses = await Promise.all(detailedDataPromises);
        const detailedData = detailedDataResponses.map(res => res.response);

        const correctAnswersData = {};
        detailedData.forEach(details => {
          details.forEach(detail => {
            if (detail.type === 'multipleChoice') {
              correctAnswersData[detail.id] = detail.correct_answer;
            } else if (detail.type === 'chooseTheCorrectAnswer') {
              correctAnswersData[detail.id] = detail.correctanswer;
            }
          });
        });

        setDetailedAssessments(detailedData);
        debugger;
        setCorrectAnswers(correctAnswersData);
      }

      
    } catch (error) {
      console.error("Error fetching Assessments:", error);
    }
  };

  const capitalizeFirstLetter = (str) => {
    if (typeof str !== 'string' || str.length === 0) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleOptionChange = (assessmentId, questionId, key, value, isMultipleChoice = false) => {
    setSelectedAssessmentId(assessmentId);
    setSelectedOptions(prev => {
      const currentOptions = prev[assessmentId]?.[questionId] || [];

      let newOptions;
      if (isMultipleChoice) {
        newOptions = currentOptions.includes(key)
          ? currentOptions.filter(option => option !== key)
          : [...currentOptions, key];
      } else {
        newOptions = [key];
      }

      return {
        ...prev,
        [assessmentId]: {
          ...prev[assessmentId],
          [questionId]: newOptions
        }
      };
    });

    setAnsweredQuestions(prev => ({
      ...prev,
      [assessmentId]: {
        ...prev[assessmentId],
        [questionId]: true
      }
    }));
  };
  const checkExistingReport = async (userId, assessmentId) => {
    try {
      // Fetch all assessment reports
      const response = await get(`assessmentreports`);
  
      // Filter the reports based on userId and assessmentId
      const filteredReports = response.response.filter(report => report.user.id === userId && report.assessment.id === assessmentId);
      setAssessmentReportId(filteredReports[0].id);
      // Return the first matching report if exists, otherwise return null
      return filteredReports.length > 0 ? filteredReports[0] : null;
      
    } catch (error) {
      console.error("Error checking existing report:", error);
      return null;
    }
  };
  const submitAnswers = async (status = 'submitted') => {
    if (currentAssessmentIndex === -1) {
        return; // No assessment selected
    }

    const selectedAssessment = assessmentsData[currentAssessmentIndex];
    const questions = detailedAssessments[currentAssessmentIndex] || [];

    let allAnswersArray = questions.map(question => {
        const questionId = question.id;
        const selectedOption = selectedOptions[selectedAssessment.id]?.[questionId] || [];
        const correctAnswer = correctAnswers[questionId];
        let isCorrect = false;

        if (question.type === 'chooseTheCorrectAnswer') {
            if (Array.isArray(correctAnswer)) {
                isCorrect = selectedOption
                    .map(opt => capitalizeFirstLetter(opt))
                    .sort()
                    .toString() === correctAnswer
                    .map(ans => capitalizeFirstLetter(ans))
                    .sort()
                    .toString();
            } else {
                isCorrect = capitalizeFirstLetter(selectedOption[0]) === capitalizeFirstLetter(correctAnswer);
            }
        } else if (question.type === 'multipleChoice') {
            isCorrect = capitalizeFirstLetter(selectedOption[0]) === capitalizeFirstLetter(correctAnswer);
        } else if (question.type === 'fillInTheBlanks') {
            setHasFillInTheBlanks(true);
        }

        return {
            questionId,
            questionTitle: question.question,
            selectedOption,
            questionType: question.type,
            isCorrect: question.type === 'fillInTheBlanks' ? null : isCorrect,
            assessmentId: selectedAssessment.id // Include assessmentId in each answer for reference
        };
    });

    // Filter out answers with empty questionIds
    allAnswersArray = allAnswersArray.filter(answer => answer.questionId !== null);

    let percentage = 0;
    if (!hasFillInTheBlanks) {
        const totalQuestions = questions.length;
        const correctAnswersCount = allAnswersArray.reduce((count, answer) => count + (answer.isCorrect ? 1 : 0), 0);
        percentage = (correctAnswersCount / totalQuestions) * 100;
    }

    // Pass the calculated percentage directly to submitResult
    submitResult(status, allAnswersArray, [selectedAssessment.id], percentage);
    setFormSubmitted(true);
};
  
  const submitResult = async (status, answers, assessmentIdList, correctPercentage) => {
    if (answers.length === 0 && assessmentIdList.length === 0) {
      return; // No answers or assessment IDs to submit
    }
  
    const authorId = sessionStorage.getItem("authorid");
    const organizationId = sessionStorage.getItem("organizationid");
    debugger;
    const formData = {
      user: authorId,
      organization: organizationId,
      assessment: assessmentIdList[0] || "", // Use the first assessmentId from the list
      answers: answers,
      correctPercentage: hasFillInTheBlanks ? null : correctPercentage,
      submissionStatus: status,
      slotId:null
    };
  
    try {
      const exists = await checkExistingReport(authorId, selectedassessmentId);
      
      const responseData = exists ? await put(`assessmentreports/${assessmentReportId}`, formData) : await post('assessmentreports', formData);
      if (responseData.statusCode === 201) {
        if(status === 'saved'){
          toast.success('Answers saved successfully for this assessment!', {
            onClose: () => {
              window.location.reload(); 
            }
          });
            await post('globalevents', {
              name: `Assessment Saved`,
              type: "assessmentevent",
              createdAt: new Date(),
              organization: sessionStorage.getItem("organizationid"),
              user: sessionStorage.getItem("authorid"),
              index: assessmentIdList[0]
          });
        } else{
          toast.success('Answers submitted successfully for this assessment!', {
            onClose: () => {
              window.location.reload(); 
            }
          });
          await post('globalevents', {
            name: `Assessment Submitted`,
            type: "assessmentevent",
            createdAt: new Date(),
            organization: sessionStorage.getItem("organizationid"),
            user: sessionStorage.getItem("authorid"),
            index: assessmentIdList[0]
        });
        }        
      }if (responseData.statusCode === 201) {
        if(status === 'saved'){
          toast.success('Answers saved successfully for this assessment!', {
            onClose: () => {
              window.location.reload(); 
            }
          });
            await post('globalevents', {
              name: `Assessment Saved`,
              type: "assessmentevent",
              createdAt: new Date(),
              organization: sessionStorage.getItem("organizationid"),
              user: sessionStorage.getItem("authorid"),
              index: assessmentIdList[0]
          });
        } else if(responseData.statusCode === 200){
          toast.success('Answers submitted successfully for this assessment!', {
            onClose: () => {
              window.location.reload(); 
            }
          });
          await post('globalevents', {
            name: `Assessment Submitted`,
            type: "assessmentevent",
            createdAt: new Date(),
            organization: sessionStorage.getItem("organizationid"),
            user: sessionStorage.getItem("authorid"),
            index: assessmentIdList[0]
        });
        }        
      } else {
        toast.error('Failed to submit answers. Please try again.');
      }
      console.log(responseData);
    } catch (error) {
      console.error('Error submitting assessment answers:', error);
      toast.error('Failed to submit answers. Please try again.');
    }
  };
  
  
  // const submitResult = async (status) => {
  //   if (allAnswers.length === 0) {
  //     return; // No answers to submit
  //   }
  
  //   const authorId = sessionStorage.getItem("authorid");
  //   const organizationId = sessionStorage.getItem("organizationid");
  
  //   const formData = {
  //     user: authorId,
  //     organization: organizationId,
  //     assessment: assessmentIdPost || "", // Use assessmentId from the first answer
  //     answers: allAnswers,
  //     correctPercentage: hasFillInTheBlanks ? null : correctPercentage,
  //     submissionStatus: status
  //   };
  
  //   try {
  //     const responseData = await post('assessmentreports', formData);
  //     if (responseData.statusCode === 201) {
  //       toast.success('Answers submitted successfully for this assessment!', {
  //         onClose: () => {
  //           window.location.reload(); // Reload page after successful submission
  //         }
  //       });
  //     } else {
  //       toast.error('Failed to submit answers. Please try again.');
  //     }
  //     console.log(responseData);
  //   } catch (error) {
  //     console.error('Error submitting assessment answers:', error);
  //     toast.error('Failed to submit answers. Please try again.');
  //   }
  // };
  

  const startTimer = (durationInMinutes) => {
    
    const durationInSeconds = durationInMinutes * 60; // Convert minutes to seconds
    setTimeLeft(durationInSeconds); // Initialize time left
  
    const timerInterval = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timerInterval); // Clear interval when timer reaches zero
          submitAnswers('submitted'); // Submit answers when timer ends
          return 0;
        } else {
          return prevTime - 1; // Decrement time left by 1 second
        }
      });
    }, 1000); // Interval set to 1000 ms (1 second)
    
  };
    

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const renderDetail = (assessmentId, detail) => {
    const questionId = detail.id;
    const selectedOption = selectedOptions[assessmentId]?.[questionId] || [];

    switch (detail.type) {
      case 'multipleChoice':
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail.question) }}></div>
            {['answer1', 'answer2', 'answer3', 'answer4'].map(answer => (
              <div key={answer} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <input
                  type="radio"
                  value={detail[answer]}
                  name="quizoption"
                  checked={selectedOption.includes(answer)}
                  onChange={() => handleOptionChange(assessmentId, questionId, answer, detail[answer], true)}
                  style={{ marginRight: '10px' }}
                />
                <label dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail[answer]) }}></label>
              </div>
            ))}
          </div>
        );
      case 'chooseTheCorrectAnswer':
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail.question) }}></div>
            {['answer1', 'answer2', 'answer3', 'answer4'].map(answer => (
              <div key={answer} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <input
                  type="checkbox"
                  value={detail[answer]}
                  checked={selectedOption.includes(answer)}
                  onChange={() => handleOptionChange(assessmentId, questionId, answer, detail[answer], true)}
                  style={{ marginRight: '10px' }}
                />
                <label dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail[answer]) }}></label>
              </div>
            ))}
          </div>
        );
      case 'fillInTheBlanks':
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail.question) }}></div>
            <p className="mt-4">Answer: <span><input type="text" value={selectedOption[0] || ''} onChange={(e) => handleOptionChange(assessmentId, questionId, e.target.value)} /></span></p>
          </div>
        );
      default:
        return <p>{detail.question}</p>;
    }
  };
  
  const renderAssessmentSelection = () => {
    return assessmentsData.map((assessment, index) => (
      <Col md={4} key={assessment.id}>
        <div onClick={() => {
          setCurrentAssessmentIndex(index);
          startTimer(assessment.timer); // Assuming assessment has a timeLimit property
          // post('globalevents', {
          //   name: `Assessment Started`,
          //   type: "assessmentevent",
          //   createdAt: new Date(),
          //   organization: sessionStorage.getItem("organizationid"),
          //   user: sessionStorage.getItem("authorid"),
          //   index: assessment.id
          // });
        }} style={{ cursor: 'pointer' }}>
          <Card className="p-5 mb-5" >
            Start Assessment: {assessment.title}
          </Card>
        </div>
      </Col>
    ));

  };

  const scrollToQuestion = (questionId) => {
    const questionElement = document.getElementById(questionId);
    if (questionElement) {
      questionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderAssessment = (index) => {
    const assessment = assessmentsData[index];
    const details = detailedAssessments[index] || [];
    const totalQuestions = details.length;
    const answeredCount = Object.keys(answeredQuestions[assessment.id] || {}).length;
      // global event call
       post('globalevents', {
        name: `Assessment Started`,
        type: "assessmentevent",
        createdAt: new Date(),
        organization: sessionStorage.getItem("organizationid"),
        user: sessionStorage.getItem("authorid"),
        index: assessment.id
    });
    return (
      <div key={`assessment-${index}`}>
        <Card className="mt-5 p-5">
          <h3>Assessment Title: {assessment.title}</h3>
          <p>Description:</p>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(assessment.description) }}></div>
          {timeLeft ? <p>Time Remaining: {formatTime(timeLeft)}</p> : 'Time is completed. Form Submitted.'}
        </Card>
        <Row>
          <Col md={8}>
            {details.map((detail, detailIndex) => (
              <Card
                key={`detail-${index}-${detailIndex}`}
                id={`question-${index}-${detailIndex}`}
                className="mt-3 p-5"
              >
                {renderDetail(assessment.id, detail)}
              </Card>
            ))}
            <div className="mt-5 d-flex justify-content-between">
              <Button onClick={() => submitAnswers('saved')} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Save Answers</Button>
              <Button onClick={() => submitAnswers('submitted')} className="ml-3 btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Submit Answers</Button>
            </div>
          </Col>
          <Col md={4} className="p-3 sticky-column">
            <h3>Navigate to Questions</h3>
            <p>Answered Questions: {answeredCount} / {totalQuestions} </p>
            <div className="d-flex">
              {details.map((detail, detailIndex) => (
                <div
                  className="px-3 py-2 m-1"
                  key={`question-index-${index}-${detailIndex}`}
                  onClick={() => scrollToQuestion(`question-${index}-${detailIndex}`)}
                  style={{
                    cursor: 'pointer',
                    color: 'blue',
                    border: '1px solid #ccc',
                    backgroundColor: answeredQuestions[assessment.id]?.[detail.id] ? '#fff2d8' : '#fff' // Change background color if answered
                  }}
                >
                  {detailIndex + 1}
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    );
  };
  

  return (
    <Row>
      {renderAssessmentSelection()}
      {currentAssessmentIndex !== -1 && renderAssessment(currentAssessmentIndex)}
      <ToastContainer />
      {/* {hasFillInTheBlanks ? (
        <p>Some questions require manual grading. Please wait for the result.</p>
      ) : (
        correctPercentage > 0 && (
          <p>Your score: {correctPercentage.toFixed(2)}%</p>
        )
      )} */}
    </Row>
  );
}

export default AssessmentView;
