import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import DashboardContent from './DashboardContent';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import SelectField from './SelectField';  // Assuming SelectField supports multi-select
import { del, get, post } from './rest';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import MultiSelectField from './MultiSelectField';
import QuillEditor from './QuillEditor';

const QuizCalendar = () => {
  const [events, setEvents] = useState([]);
  const [content, setContent] = useState(null);
  const [showContent, setShowContent] = useState(false);
  
  const [assessmentData, setAssessmentData] = useState({
    start_date: null,
    end_date: null,
    assessmentQuiz: '',
    slots: []  // Initialize as an array for multi-select
  });

  const [optionsAssessments, setOptionsAssessment] = useState([]);
  const [optionsStudents, setOptionsStudents] = useState([]);
  
  useEffect(() => {
    fetchQuizCalendar();
    fetchAssessmentOptions();
    fetchStudentOptions();
  }, []);
  
  const fetchQuizCalendar = async () => {
    try {
      const response = await get('quizcalendar');
      const timeslotsResponse = await get('/quizcalendartimeslot');
      const timeslots = timeslotsResponse.response;

      const formattedEvents = response.response.filter(org=> org.organization.id === organizationid).map(item => ({
        id: item._id,
        title: `${item.assessmentQuiz.title}`,
        start: new Date(item.start_date),
        end: new Date(item.end_date),
        slots: timeslots
          .filter(slot => slot.quiz_calendar && slot.quiz_calendar.id === item._id)
          .map(slot => ({
            id: slot._id,
            subscribed_students: slot.subscribed_students.map(student => ({
              value: student.id,
              label: student.username + ' (' + student.email + ')'
            }))
          }))
      }));

      setEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching Quiz Calendar:', error);
    }
  };

  const organizationid = sessionStorage.getItem("organizationid");

  const fetchAssessmentOptions = async () => {
    try {
      const response = await get('assessmentQuiz');
      debugger;
      const options = response.response
      .filter(assessment =>  assessment.organization && assessment.organization === organizationid )
      .map(assessment => ({
        value: assessment.id,
        label: assessment.title
      }));
      setOptionsAssessment(options);
    } catch (error) {
      console.error('Error fetching Assessments options:', error);
    }
  };

  const fetchStudentOptions = async () => {
    try {
      const response = await get('users');
      const options = response.response
      .filter(student => student.role.slug === 'student' && student.organization && student.organization.id === organizationid )
      .map(student => ({
        value: student.id,
        label: student.username +' (' + student.email +')'
      }));
      setOptionsStudents(options);
    } catch (error) {
      console.error('Error fetching Students options:', error);
    }
  };

  const handleChangeAssessments = (selectedOptionAssessments) => {
    setAssessmentData(prevState => ({
      ...prevState,
      assessmentQuiz: selectedOptionAssessments
    }));
  };

  const handleChangeStudents = (selectedOptionStudents) => {
    setAssessmentData(prevState => ({
      ...prevState,
      students: selectedOptionStudents  // Ensure selected students are stored as an array
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const organizationid = sessionStorage.getItem("organizationid");
        const authorid = sessionStorage.getItem("authorid");

        // Step 1: Prepare the slots with students data
        const slots = assessmentData.students.length > 0 ? [
            {
              subscribed_students: assessmentData.students.map(student => student.value)
            }
        ] : [];

        // Step 2: Create Quiz Calendar entry
        const quizCalendarData = {
            start_date: assessmentData.start_date,
            end_date: assessmentData.end_date,
            assessmentQuiz: assessmentData.assessmentQuiz,
            organization: organizationid,
            created_user: authorid,
            slots: slots,
            createdBy:authorid
        };

        const quizCalendarResponse = await post('quizcalendar', quizCalendarData);
        const quiz_calendar = quizCalendarResponse.response.quizCalendar._id;
        debugger;
        // Step 3: Optionally create slots for students if they are to be processed separately
        if (slots.length > 0) {
          const slotsData = slots.map(slot => ({
              ...slot,
              quiz_calendar: quiz_calendar,
              created_user: authorid,
              createdBy:authorid
          }));
      
          try {
              const slotsResponseData = await post('QuizCalendartimeslot/bulk-insert', slotsData);
              const quizslotIds = slotsResponseData.response.ids;
      
              // Iterate over assessmentData.students and quizslotIds simultaneously
              for (let i = 0; i < assessmentData.students.length; i++) {
                  const student = assessmentData.students[i];
                  const studentId = student.value;
      
                  // Ensure we don't go out of bounds of the quizslotIds array
                  const quizslotid = quizslotIds[i % quizslotIds.length]; // Wrap around using modulus
      
                  const notificationData = {
                      createduser: authorid,
                      user: studentId,
                      organization: organizationid,
                      link: `/assessment?id=${assessmentData.assessmentQuiz}&quiz=${quizslotid}`,
                      message: assessmentData.message,
                      type:"quizcalendar",
                      createdBy: authorid, 
                  };
      
                  await post('notifications', notificationData);
                  console.log(`Notification sent to student ${studentId} with quizslotid ${quizslotid}`);
              }
          } catch (error) {
              console.error('Error processing slots bulk insert:', error);
              // Handle error if needed
          }
      }
      
        // Step 4: Send notifications to each selected student separately
        // const studentIds = assessmentData.students.map(student => student.value);

       
    
        toast.success('Submitted successfully and sent notifications to Students.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
            window.location.reload();
          }
        });
      
   

    } catch (error) {
        console.error('Error submitting form:', error);
    }
};

  const handleDateChange = (field, date) => {
    setAssessmentData({ ...assessmentData, [field]: date });
  };

  const onClickDay = (date) => {
    const eventsOnClickedDate = events.filter(event =>
      moment(date).isBetween(moment(event.start).startOf('day'), moment(event.end).endOf('day'), null, '[]')
    );
    debugger;
    const content = eventsOnClickedDate.map(event => (
      <Card key={event.id} className="mt-3">
        <Card.Body>
          <Card.Title>
            <h5>Assessment: {event.title}</h5>
          </Card.Title>
          {event.slots.map(slot => (
            <Card.Text key={`${event.id}-${slot.startTime}`}>
              <p>Students:</p>
              <ul>
                {slot.subscribed_students.map(student => (
                  <li key={student.value}>{student.label}</li>
                ))}
              </ul>
            </Card.Text>
          ))}
          <Row>
            {/* <Col>
              <Button onClick={() => window.location='/editQuizCalendar?id='+ event.id} className='mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark'>Edit</Button>
            </Col> */}
            <Col>
              <Button onClick={() => handleDeleteCalendar(event.id)} className='mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark'>Delete Assessment From Calendar</Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    ));

    setShowContent(true);
    setContent(content);
  };

  const hideContent = () => {
    setShowContent(false);
  };

  async function handleDeleteCalendar(id) {
    try {
      await del(`quizcalendar/${id}`);
      window.location.reload();
    } catch (error) {
      console.error('Error deleting Units:', error);
    }
  }
  const handleEditorChange = (name, content) => {
    setAssessmentData((prevState) => ({
      ...prevState,
      [name]: content,
    }));
  };
  return (
    <DashboardContent pageTitle="Quiz Calendar">
      <div className="card dashboard-card-ten">
        <div className="card-body">
          <Form onSubmit={handleSubmit} className="my-5">
            <Row>
              <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                <Form.Label>Start Date:</Form.Label>
                <Form.Control type="date" name="start_date" className="form-control" value={assessmentData.start_date} onChange={(e) => handleDateChange('start_date', e.target.value)} required/>
              </Col>
              <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                <Form.Label>End Date:</Form.Label>
                <Form.Control type="date" name="end_date" className="form-control" value={assessmentData.end_date} onChange={(e) => handleDateChange('end_date', e.target.value)} min={assessmentData.start_date} required/>
              </Col>
              </Row>
                  <Row>
              <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                <Form.Label>Assessments:</Form.Label>
                <SelectField options={optionsAssessments} placeholder="Select Assessments" onChange={handleChangeAssessments} required/>
              </Col>
              <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                <Form.Label>Students:</Form.Label>
                <MultiSelectField
                  options={optionsStudents}
                  value={assessmentData.students}
                  placeholder="Select Students"
                  onChange={handleChangeStudents}
                  isMulti  // Ensure SelectField supports multi-select
                  required
                />
              </Col>
              </Row>
                  <Row>
              <Col md={8} xl={8} lg={8} xs={12} className="form-group">
              <Form.Label>Message or Note Students:</Form.Label>
              <QuillEditor name="message"
                          value={assessmentData.message}
                          onChange={(content) =>
                            handleEditorChange("message", content)
                          } 
                          required></QuillEditor>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Button type="submit" className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Submit</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>

          <Calendar
            tileContent={({ date, view }) => {
              if (view === 'month') {
                const eventsOnDate = events.filter(event => {
                  const isInRange = moment(date).isBetween(event.start, event.end, null, '[]');
                  const isStartDate = moment(date).isSame(event.start, 'day');
                  const isEndDate = moment(date).isSame(event.end, 'day');
                  return isInRange || isStartDate || isEndDate;
                });

                const uniqueAssessments = [...new Set(eventsOnDate.map(event => event.title))];

                return uniqueAssessments.length > 0 && (
                  <div className="course-container">
                    {uniqueAssessments.map((assessments, index) => (
                      <div key={index} className="course-box">
                        <span className="assessments-name">{assessments}</span>
                      </div>
                    ))}
                  </div>
                );
              }
            }}
            onClickDay={onClickDay}
            onClickOutside={hideContent}
            onActiveStartDateChange={hideContent}
            onViewChange={hideContent}
          />
          {showContent && <div className="my-5">{content}</div>}
        </div>
      </div>
      <ToastContainer />
    </DashboardContent>
  );
};

export default QuizCalendar;
