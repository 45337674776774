import { Button, Card, Col, Row } from "react-bootstrap";
import DashboardContent from "./DashboardContent";
import { useEffect, useState } from "react";
import { get } from "./rest";
import BarChart from "./BarChart"; // Make sure you have this component
import Select from "react-select"; // Import react-select for dropdown
import moment from "moment"; // Import moment.js for date manipulation
import './BarChart.css';

function DashboardOrganizationAdmin() {
  const [course, setCoursesCount] = useState(0);
  // const [enquiries, setEnquiriesCount] = useState(0);
  const [student, setStudentCount] = useState(0);
  const [lesson, setLessonCount] = useState(0);
  const [teacher, setTeacherCount] = useState(0);
  const [dailyTotal, setDailyTotal] = useState(0);
  const [weeklyTotal, setWeeklyTotal] = useState(0);
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [selectedOption, setSelectedOption] = useState({ value: 'week', label: 'This Week' });
  const [organizationPlan, setOrganizationPlan] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const authorrole = sessionStorage.getItem("rolename");
  const timeOptions = [
    { value: 'today', label: 'Today' },
    { value: 'week', label: 'This Week' },
    { value: 'lastWeek', label: 'Last Week' },
    { value: 'month', label: 'This Month' },
    { value: 'lastMonth', label: 'Last Month' },
  ];
  const [quizCount, setQuizCount] = useState(0);

  useEffect(() => {
    fetchOrganizationOptions();

    fetchCourseCount();
    // fetchEnquiriesCount();
    fetchStudentCount();
    fetchLessonsCount();
    fetchQuizCount();
    calculateTotals();
    fetchChartData(selectedOption.value);
  }, []);

  useEffect(() => {
    fetchChartData(selectedOption.value);
  }, [selectedOption]);

  const fetchCourseCount = async () => {
    try {
      const response = await get('courses');
      const role = sessionStorage.getItem("roleslug");
      const organizationid = sessionStorage.getItem("organizationid");
      let coursedata = '';

      if (role === 'superadmin') {
        coursedata = response.response.filter(admin => admin.role.slug !== "employee" && admin.role.slug !== "organizationadmin");
      } else if (role === 'organizationadmin') {
        coursedata = response.response.filter(admin => admin.role.slug !== 'superadmin' && admin.organization === organizationid);
      } else {
        coursedata = response.response.filter(admin => admin.organization === organizationid);
      }
      setCoursesCount(coursedata.length);
    } catch (error) {
      console.error('Error fetching DefaultCourse:', error);
    }
  };

  // const fetchEnquiriesCount = async () => {
  //   try {
  //     const response = await get('enquiries/count');
  //     setEnquiriesCount(response.response);
  //   } catch (error) {
  //     console.error('Error fetching enquiries:', error);
  //   }
  // };

  const fetchLessonsCount = async () => {
    try {
      const response = await get('lms');
      const role = sessionStorage.getItem("roleslug");
      const organizationid = sessionStorage.getItem("organizationid");
      let lmsdata = '';

      if (role === 'superadmin') {
        lmsdata = response.response.filter(lms => lms.lms_cluster !== null && lms.lms_cluster.name === 'superadmin');
      } else if (role === 'organizationadmin') {
        lmsdata = response.response.filter(lms => lms.organization && lms.lms_cluster !== null && lms.organization === organizationid && lms.lms_cluster.name === 'organizationadmin');
      } else {
        lmsdata = response.response.filter(lms => lms.organization && lms.lms_cluster !== null && lms.organization === organizationid && lms.lms_cluster.name === 'organizationadmin');
      }
      setLessonCount(lmsdata.length);
    } catch (error) {
      console.error('Error fetching LMS:', error);
    }
  };

  const fetchStudentCount = async () => {
    const organizationid = sessionStorage.getItem("organizationid");
    try {
      const response = await get('users');
      const studentsData = response.response.filter(emp => emp.role.slug === 'student' && emp.organization && emp.organization.id === organizationid);
      const teacherData = response.response.filter(emp => emp.role.slug === 'teacher' && emp.organization && emp.organization.id === organizationid);

      setStudentCount(studentsData.length);
      setTeacherCount(teacherData.length);
    } catch (error) {
      console.error('Error fetching Students:', error);
    }
  };

  const calculateTotals = async () => {
    try {
      const response = await get('transactions');
      const transactions = response.response;

      let dailyTotal = 0;
      let weeklyTotal = 0;
      let monthlyTotal = 0;

      const today = moment();
      const startOfWeek = moment().startOf('week');
      const startOfMonth = moment().startOf('month');

      transactions.forEach(transaction => {
        const createdAt = moment(transaction.createdAt);
        if (createdAt.isSame(today, 'day')) {
          dailyTotal += transaction.amount;
        }
        if (createdAt.isSameOrAfter(startOfWeek)) {
          weeklyTotal += transaction.amount;
        }
        if (createdAt.isSameOrAfter(startOfMonth)) {
          monthlyTotal += transaction.amount;
        }
      });

      setDailyTotal(dailyTotal);
      setWeeklyTotal(weeklyTotal);
      setMonthlyTotal(monthlyTotal);
    } catch (error) {
      console.error('Error calculating totals:', error);
    }
  };
const organizationid = sessionStorage.getItem("organizationid");
const fetchChartData = async (period) => {
  try {
      const response = await get('transactions');
      console.log('Transactions Response:', response);

      // Ensure organizationid is defined and correct
      const organizationid = sessionStorage.getItem("organizationid");
      console.log('Organization ID:', organizationid);

      const transactions = response.response.filter(transaction => {
          // console.log('Transaction User Organization:', transaction.user.organization);
          // console.log('Comparing with Organization ID:', organizationid);
          return transaction.user && transaction.user.organization === organizationid;
      });

      console.log('Filtered Transactions:', transactions);

      const data = [];

      if (period === 'today') {
          const startOfDay = moment().startOf('day');
          const endOfDay = moment().endOf('day');

          for (let hour = startOfDay.clone(); hour <= endOfDay; hour.add(1, 'hour')) {
              const hourlyTotal = transactions
                  .filter(transaction => moment(transaction.createdAt).isSame(hour, 'hour'))
                  .reduce((sum, transaction) => sum + transaction.amount, 0);
              data.push({ label: hour.format('HH:mm'), value: hourlyTotal });
          }
      } else if (period === 'week' || period === 'lastWeek') {
          const startOfWeek = period === 'week' ? moment().startOf('week') : moment().subtract(1, 'week').startOf('week');
          const endOfWeek = period === 'week' ? moment().endOf('week') : moment().subtract(1, 'week').endOf('week');

          for (let day = startOfWeek.clone(); day <= endOfWeek; day.add(1, 'day')) {
              const dailyTotal = transactions
                  .filter(transaction => moment(transaction.createdAt).isSame(day, 'day'))
                  .reduce((sum, transaction) => sum + transaction.amount, 0);
              data.push({ label: day.format('DD-MM'), value: dailyTotal });
          }
      } else if (period === 'month' || period === 'lastMonth') {
          const startOfMonth = period === 'month' ? moment().startOf('month') : moment().subtract(1, 'month').startOf('month');
          const endOfMonth = period === 'month' ? moment().endOf('month') : moment().subtract(1, 'month').endOf('month');

          for (let day = startOfMonth.clone(); day <= endOfMonth; day.add(1, 'day')) {
              const dailyTotal = transactions
                  .filter(transaction => moment(transaction.createdAt).isSame(day, 'day'))
                  .reduce((sum, transaction) => sum + transaction.amount, 0);
              data.push({ label: day.format('DD-MM'), value: dailyTotal });
          }
      }

      setChartData(data);
  } catch (error) {
      console.error('Error fetching chart data:', error);
  }
};


  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const fetchOrganizationOptions = async () => {
    try {
      debugger;
        const response = await get('organization');
        debugger;
        const data = response.response.filter(org => org.id === organizationid);
        const plan = data.map(plan => plan.subscriptionType ? plan.subscriptionType.subscriptiontype :'');
        const startDate = data.map(plan => plan.subscription ? plan.subscription.createdAt :null);
        const validStartDates = startDate.filter(date => date !== null);
        const endDate = validStartDates.map(date => {
            if (date) {
                const calculatedEndDate = new Date(date);
                calculatedEndDate.setFullYear(calculatedEndDate.getFullYear() + 1); // Add 1 year (365 days)
                return calculatedEndDate;
            }
            return null;
        });
        setStartDate(startDate);
        debugger
        setEndDate(endDate);
        setOrganizationPlan(plan[0]);
    } catch (error) {
        console.error('Error fetching organization options:', error);
    }
  };
  const fetchQuizCount = async () => {
    try {
      const response = await get('assessmentquiz');
      let quizdata = response.response.filter(quiz => quiz.organization && quiz.organization === organizationid);
      
      setQuizCount(quizdata.length);
    } catch (error) {
      console.error('Error fetching LMS:', error);
    }
  };
  return (
    <DashboardContent pageTitle="Dashboard">
      <Row className="py-4">
            <Col md={4}>
              
                {authorrole === 'Organization Admin' ?
                <Card className="p-5">
                  <p className="m-0 me-5"> Plan: {organizationPlan}</p>
                  <p className="m-0 me-5 mb-4">Validity: {startDate.map(date => moment(date).format('DD/MM/YYYY')).join(', ')} - {endDate.map(date => moment(date).format('DD/MM/YYYY')).join(', ')}</p>
                  <Button className="secondary-btn" onClick={()=>{window.location.href='/upgrade'}}>Upgrade / Renewal</Button>
                </Card>:
                ''}
              </Col>
            </Row>
      <Row>
        <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-green ">
                  <i className="fa fa-landmark text-green"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">No. of Students</div>
                  <div className="item-number" id="ClassCount">
                    {student}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-blue ">
                <i className="fa-solid fa-chart-line text-blue"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">No. of Quiz</div>
                  <div className="item-number" id="ClassCount">
                    {quizCount}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-yellow ">
                  <i className="fa fa-list text-yellow"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">Lessons Count</div>
                  <div className="item-number" id="ClassCount">
                    {lesson}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-magenta ">
                  <i className="fa-solid fa-chalkboard-user text-mauvelous"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">Topics Count</div>
                  <div className="item-number" id="ClassCount">
                    {course}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <Select
            value={selectedOption}
            onChange={handleChange}
            options={timeOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} className="graph-container">
          <BarChart data={chartData} />
        </Col>
      </Row>
    </DashboardContent>
  );
}

export default DashboardOrganizationAdmin;
