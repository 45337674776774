import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import InactiveOrganizationModal from './InactiveOrganizationModal';

const ProtectedRoute = ({ isOrganizationActive }) => {
  const [showModal, setShowModal] = useState(false);
  const isAuthenticated = !!sessionStorage.getItem('authorid');

  // List of paths that don't require authentication
  const exceptions = ["/forgotpassword", "/otp", "/setpassword", "/", "/home", "/signup", "/login"];

  // Get the current page URL
  const currentPage = window.location.pathname;

  useEffect(() => {
    if (!isOrganizationActive && currentPage !== '/inactive-organization') {
      setShowModal(true);
    }
  }, [isOrganizationActive, currentPage]);

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.reload(); // Try again by reloading the page
  };

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.href = '/';
  };

  if (!isAuthenticated && !exceptions.includes(currentPage)) {
    // Redirect to login if not authenticated and trying to access a protected route
    return <Navigate to="/" />;
  }

  if (isAuthenticated && exceptions.includes(currentPage)) {
    // Redirect authenticated users trying to access login-related pages to home
    return <Navigate to="/home" />;
  }

  // Render the protected component with the modal
  return (
    <>
      {showModal && <InactiveOrganizationModal show={showModal} handleClose={handleCloseModal} handleLogout={handleLogout} />}
      <Outlet />
    </>
  );
};

export default ProtectedRoute;
