import DOMPurify from "dompurify";
import { Card, Row, Button, Col } from "react-bootstrap";
import { get, post,put } from "./rest";
import { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import DashboardContent from "./DashboardContent";

function TakeAssessment() {
  const [questions, setQuestions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [correctAnswers, setCorrectAnswers] = useState({});
  const [correctPercentage, setCorrectPercentage] = useState(0);
  const [hasFillInTheBlanks, setHasFillInTheBlanks] = useState(false);
  const [allAnswers, setAllAnswers] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [id, setId] = useState(null);
  const [slotId, setSlotId] = useState(null);
  const [assessmentReportId, setAssessmentReportId] = useState(null);
  const [questionsCount, setQuestionsCount] = useState(null);
  const [difficultyLevel, setQuestionsDifficultyLevel] = useState(null);
  
  const [quizData, setQuizData] = useState(null);
  const [timer, setTimer] = useState(null);
  const [quizStarted, setQuizStarted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const timerRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const quizslotid = searchParams.get("quiz");
    setId(id);
    setSlotId(quizslotid);
    if (id && quizslotid) {
      debugger;
      fetchQuizData(quizslotid);
    }
  }, [location.search]);

  const fetchQuizData = async (slotId) => {
    try {
      const response = await get(`quizcalendartimeslot/${slotId}`);
      const quizdata = response.response;
      setQuizData(quizdata);
    } catch (error) {
      console.error("Error fetching quiz data:", error);
    }
  };

  const isQuizActive = () => {
    if (!quizData || !quizData.quiz_calendar.start_date || !quizData.quiz_calendar.end_date) {
      return false;
    }

    const startDate = new Date(quizData.quiz_calendar.start_date);
    const endDate = new Date(quizData.quiz_calendar.end_date);
    const currentDate = new Date();

    return currentDate >= startDate && currentDate <= endDate;
  };

  useEffect(() => {
    if (id && slotId && isQuizActive()) {      
      debugger;
      fetchQuestions(id);
    }
  }, [quizData, id, slotId]);
  
  useEffect(() => {
    if (id && isQuizActive()) {
      debugger;      
      fetchQuestions(id);
    }
  }, [quizData, id]);

  const fetchQuestions = async (id) => {
    try {
        const assessmentq = await get(`assessmentQuiz/` + id);
        let questionsCount = '';
        let assessmentQuizId = '';
        let questionsDifficultyLevel = '';

        if (assessmentq.response.type === "random") {
            assessmentQuizId = assessmentq.response.assessmentQuiz;
            questionsCount = assessmentq.response.questionscount; // Get the questions count
            questionsDifficultyLevel = assessmentq.response.difficultyLevel; // Get the questions count
            setQuestionsCount(questionsCount);
        } else {
            assessmentQuizId = id;
        }

        const response = await get(`assessment?assessmentQuiz=${assessmentQuizId}`);
      let questions = '';
      if (assessmentq.response.type === "random") {
        questions = response.response.filter(qun => qun.difficultyLevel && qun.difficultyLevel === questionsDifficultyLevel);
      } else {
        questions = response.response;
      }
      debugger;
        // const questions = response.response;
        const correctAnswersData = {};

        // Randomize the questions
        const randomizedQuestions = questions.sort(() => Math.random() - 0.5);

        // Slice the randomized questions to get only the specified count
        const selectedQuestions = randomizedQuestions.slice(0, questionsCount);

        selectedQuestions.forEach(question => {
            if (question.type === 'multipleChoice') {
                correctAnswersData[question.id] = question.correct_answer;
            } else if (question.type === 'chooseTheCorrectAnswer') {
                correctAnswersData[question.id] = question.correctanswer;
            }
            setTimer(question.assessmentQuiz.timer);
        });

        setQuestions(selectedQuestions);
        setCorrectAnswers(correctAnswersData);
    } catch (error) {
        console.error("Error fetching questions:", error);
    }
};


  const capitalizeFirstLetter = (str) => {
    if (typeof str !== 'string' || str.length === 0) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleOptionChange = (questionId, key, value, isMultipleChoice = false) => {
    setSelectedOptions(prev => {
      const currentOptions = prev[questionId] || [];

      let newOptions;
      if (isMultipleChoice) {
        newOptions = currentOptions.includes(key)
          ? currentOptions.filter(option => option !== key)
          : [...currentOptions, key];
      } else {
        newOptions = [key];
      }

      return {
        ...prev,
        [questionId]: newOptions
      };
    });

    setAnsweredQuestions(prev => ({
      ...prev,
      [questionId]: true
    }));
  };
  const checkExistingReport = async (userId, assessmentId) => {
    try {
      // Fetch all assessment reports
      const response = await get(`assessmentreports`);
  
      // Filter the reports based on userId and assessmentId
      const filteredReports = response.response.filter(report => report.user.id === userId && report.assessment.id === assessmentId);
      setAssessmentReportId(filteredReports[0].id);
      // Return the first matching report if exists, otherwise return null
      return filteredReports.length > 0 ? filteredReports[0] : null;
      
    } catch (error) {
      console.error("Error checking existing report:", error);
      return null;
    }
  };
  
  const submitAnswers = async (status = 'submitted') => {
    let allAnswersArray = [];
    let totalQuestions = 0;
    let correctAnswersCount = 0;
    let hasFillInTheBlanks = false;

    questions.forEach(question => {
      const questionId = question.id;
      const selectedOption = selectedOptions[questionId] || [];
      const correctAnswer = correctAnswers[questionId];
      let isCorrect = false;

      if (question.type === 'chooseTheCorrectAnswer') {
        if (Array.isArray(correctAnswer)) {
          isCorrect = selectedOption
            .map(opt => capitalizeFirstLetter(opt))
            .sort()
            .toString() === correctAnswer
            .map(ans => capitalizeFirstLetter(ans))
            .sort()
            .toString();
        } else {
          isCorrect = capitalizeFirstLetter(selectedOption[0]) === capitalizeFirstLetter(correctAnswer);
        }
      } else if (question.type === 'multipleChoice') {
        isCorrect = capitalizeFirstLetter(selectedOption[0]) === capitalizeFirstLetter(correctAnswer);
      } else if (question.type === 'fillInTheBlanks') {
        hasFillInTheBlanks = true;
      }

      if (question.type !== 'fillInTheBlanks') {
        totalQuestions++;
        if (isCorrect) {
          correctAnswersCount++;
        }
      }

      allAnswersArray.push({
        questionId,
        questionTitle: question.question,
        selectedOption,
        questionType: question.type,
        isCorrect: question.type === 'fillInTheBlanks' ? null : isCorrect
      });
    });

    setAllAnswers(allAnswersArray);
    setHasFillInTheBlanks(hasFillInTheBlanks);

    if (!hasFillInTheBlanks) {
      const percentage = (correctAnswersCount / totalQuestions) * 100;
      setCorrectPercentage(percentage);
    }

    const authorId = sessionStorage.getItem("authorid");
    const organizationId = sessionStorage.getItem("organizationid");

    const formData = {
      user: authorId,
      organization: organizationId,
      assessment: id,
      answers: allAnswersArray,
      correctPercentage: hasFillInTheBlanks ? null : correctPercentage,
      submissionStatus: status,
      slotId:slotId,
      createdBy: authorId
    };
    // const searchParams = new URLSearchParams(location.search);
    // const retake = searchParams.get('retake');
    try {
      const exists = await checkExistingReport(authorId, id);
      
      const responseData = exists ? await put(`assessmentreports/${assessmentReportId}`, formData) : await post('assessmentreports', formData);
      
      if (responseData.statusCode === 201) {
        if(status === 'saved'){
          toast.success('Answers saved successfully for this assessment!', {
            onClose: () => {
              window.location.reload(); // Reload page after successful submission
            }
          });
        } else{
          toast.success('Answers submitted successfully for this assessment!', {
            onClose: () => {
              window.location.reload(); // Reload page after successful submission
            }
          });
        }
      } else if (responseData.statusCode === 200) {
        if(status === 'saved'){
          toast.success('Answers saved successfully for this assessment!', {
            onClose: () => {
              window.location.reload(); // Reload page after successful submission
            }
          });
        } else{
          toast.success('Answers submitted successfully for this assessment!', {
            onClose: () => {
              window.location.href = '/results'
            }
          });
        }
      } else {
        toast.error('Failed to submit answers. Please try again.');
      }
      console.log(responseData);
    } catch (error) {
      console.error('Error submitting assessment answers:', error);
      toast.error('Failed to submit answers. Please try again.');
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      submitAnswers('submitted');
    }
  }, [timeLeft]);

  const startQuiz = async () => {
    await post('globalevents', {
      name: `Assessment Started`,
      type: "assessmentevent",
      createdAt: new Date(),
      organization: sessionStorage.getItem("organizationid"),
      user: sessionStorage.getItem("authorid"),
      index: id
  });
    if (timer) {
      setQuizStarted(true);
      setTimeLeft(timer * 60);

      timerRef.current = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 0) {
            clearInterval(timerRef.current);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      setQuizStarted(true);
    }
  };

  const renderDetail = (detail, detailIndex) => {
    const questionId = detail.id;
    const selectedOption = selectedOptions[questionId] || [];
    const totalQuestions = selectedOption.length;
    const answeredCount = Object.keys(answeredQuestions[questionId.id] || {}).length;
    switch (detail.type) {
      case 'multipleChoice':
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail.question) }}></div>
            {['answer1', 'answer2', 'answer3', 'answer4'].map(answer => (
              <label className="d-flex align-content-center" key={answer}>
                <input
                  type="radio"
                  value={detail[answer]}
                  checked={selectedOption.includes(answer)}
                  onChange={() => handleOptionChange(questionId, answer, detail[answer], true)}
                />
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail[answer]) }}></div>
              </label>
            ))}
          </div>
        );
      case 'chooseTheCorrectAnswer':
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail.question) }}></div>
            {['answer1', 'answer2', 'answer3', 'answer4'].map(answer => (
              <div key={answer} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <input
                  type="checkbox"
                  value={detail[answer]}
                  checked={selectedOption.includes(answer)}
                  onChange={() => handleOptionChange(questionId, answer, detail[answer], true)}
                />
                <label dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail[answer]) }}></label>
              </div>
            ))}
          </div>
        );
      case 'fillInTheBlanks':
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail.question) }}></div>
            <p className="mt-4">Answer: <span><input type="text" value={selectedOption[0] || ''} onChange={(e) => handleOptionChange(questionId, e.target.value)} /></span></p>
          </div>
        );
      default:
        return <p>{detail.question}</p>;
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <DashboardContent pageTitle="Assessment">
      {!quizStarted && (
        <div className="text-center mt-5">
          <Button onClick={startQuiz} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Start Quiz</Button>
        </div>
      )}

      {quizStarted && isQuizActive() && questions.length > 0 && (
        <Row>
          <Col md={8}>
            <Card className="mt-5 p-5">
              <h3>Assessment Questions</h3>
              {timeLeft !== null && timer && (
                <div className="timer">
                  <h4>Time Left: {formatTime(timeLeft)}</h4>
                </div>
              )}
            </Card>
            {questions.map((detail, detailIndex) => (
              <Card
                key={`detail-${detailIndex}`}
                id={`question-${detailIndex}`}
                className="mt-3 p-5"
              >
                {renderDetail(detail, detailIndex)}
              </Card>
            ))}
            <div className="d-flex justify-content-between">
              <Button onClick={() => submitAnswers('saved')} className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Save Answers</Button>
              <Button onClick={() => submitAnswers('submitted')} className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Submit Answers</Button>
            </div>
          </Col>
          <Col md={4} className="p-3 sticky-column">
            <h3>Navigate to Questions</h3>
            <p>Answered Questions: {Object.keys(answeredQuestions).length} / {questions.length}</p> {/* Display answered and total questions count */}

            <div className="d-flex flex-column">
              {questions.map((detail, detailIndex) => (
                <div
                  className="px-3 py-2 m-1"
                  key={`question-index-${detailIndex}`}
                  onClick={() => document.getElementById(`question-${detailIndex}`).scrollIntoView({ behavior: 'smooth' })}
                  style={{
                    cursor: 'pointer',
                    color: 'blue',
                    border: '1px solid #ccc',
                    backgroundColor: answeredQuestions[detail.id] ? '#fff2d8' : '#fff'
                  }}
                >
                  {detailIndex + 1}
                </div>
              ))}
            </div>
          </Col>
          {/* {hasFillInTheBlanks ? (
            <p>Some questions require manual grading. Please wait for the result.</p>
          ) : (
            correctPercentage > 0 && (
              <p>Your score: {correctPercentage.toFixed(2)}%</p>
            )
          )} */}
          <ToastContainer />
        </Row>
      )}

      {!quizStarted && !isQuizActive() && (
        <div className="mt-5 text-center">
          <h3>Quiz is not active</h3>
          <p>The quiz is not currently available for assessment.</p>
          {quizData && quizData.start_date && (
            <p>Start Date: {new Date(quizData.start_date).toLocaleDateString()}</p>
          )}
          {quizData && quizData.end_date && (
            <p>End Date: {new Date(quizData.end_date).toLocaleDateString()}</p>
          )}
        </div>
      )}
    </DashboardContent>
  );
}

export default TakeAssessment;
