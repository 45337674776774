import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const InactiveOrganizationModal = ({ show, handleClose, handleLogout }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Organization Inactive</Modal.Title>
      </Modal.Header>
      <Modal.Body>Your organization is currently inactive. Please try again later or logout.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className='plain-btn' onClick={handleClose}>
          Try Again
        </Button>
        <Button variant="primary" className="secondary-btn" onClick={handleLogout}>
          Logout
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InactiveOrganizationModal;
