import React, { useEffect, useState } from "react";
import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { get, put } from "./rest";
import { ToastContainer, toast } from "react-toastify";

function EditIndustry() {
  const [formData, setFormData] = useState({
    industry: "",
    thumbnail: "",
  });
  const [creating, setCreating] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [thumbnailURL, setThumbnailURL] = useState('');
  const [id, setId] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setId(id); // Set the ID value in the state variable
    fetchIndustries(id); // Fetch Industries data based on the ID
  }, [location.search]);
  const [Industries, setIndustries] = useState([]);

  const fetchIndustries = async (id) => {
    try {
        const response = await get('categories/' + id);
        setIndustries(response.response);
      // Update form data with fetched organization data
      setFormData(response.response);
      
    } catch (error) {
      console.error("Error fetching Industries:", error);
    }
  };
  const [response, setResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleThumbnailChange = async (event) => {
    setUploading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file);
    formData.append('path', 'default/courses/thumbnail'); 
  
    try {
        debugger;
        const response = await fetch('http://greenbugapi.adef.tech/upload', {
            method: 'POST',
            body: formData,
        }); 
        if (response.status === 201) { // Checking for response.ok instead of statusCode === 200
            const data = await response.json(); // Assuming the response is in JSON format
            debugger;
            if (data.response.length > 0) {
              const firstObject = data.response[0]; // Accessing the first object in the array
              const url = firstObject.url; // Assuming the URL key is 'url' in the object
              setThumbnailURL(url); // Set the thumbnail URL
              setFormData(prevState => ({
                ...prevState,
                thumbnail: url
              }));
              toast.success('Thumbnail Uploaded successfully');
            } 
        } else {
            toast.error('Failed to upload image');
        }
    } catch (error) {
        console.error('Error uploading image:', error);
        toast.error('Failed to upload image');
    } finally {
      setUploading(false); // Set uploading to false when upload completes
    }
};
  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreating(true);

    try {
      const updatedFormData = {
        ...formData,
        thumbnail: thumbnailURL || formData.thumbnail,
        updatedBy : sessionStorage.getItem("authorid")
      };
      const responseData = await put("categories/" + id, updatedFormData);
      setResponse(responseData);
      debugger;
      if (responseData.statusCode === 200) {
        toast.success("Updated Successfully", {
          onClose: () => {
            window.location.href = "/categories";
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setCreating(false);

    }
  };

  return (
    <DashboardContent pageTitle="Edit Category">
      <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3>Edit Category</h3>
                </div>
              </div>
              <div className="student-info">
                <Form
                  onSubmit={handleSubmit}
                  id="editIndustry"
                  className="mt-5"
                >
                  <Row>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>
                        Category Title:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="industry"
                        name="industry"
                        className="form-control"
                        value={formData.industry}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label >Thumbnail:</Form.Label>
                      {/* <div className="wrapper">
                        <div className="box">
                          <div className="js--image-preview"></div>
                          <div className="upload-options"> */}
                              <Form.Control type="file" id="imgfile" className="image-upload" accept="image/*" onChange={handleThumbnailChange}  />
                            <img src={formData.thumbnail} alt="thumnail" width={100}></img>

                          {/* </div>
                        </div>
                      </div> */}
                    </Col>
                </Row>
                <Row>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Button type="submit" disabled={uploading} className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >
                        {uploading ? 'Uploading...' : 'Update Category'}
                      </Button>
                      <p>{creating ? 'Updating' : ''}</p>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </DashboardContent>
  );
}

export default EditIndustry;
